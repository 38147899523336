export interface ApplePayTokenData {
  version: 'EC_v1' | 'RSA_v1';
  data: string;
  signature: string;
  header: {
    ephemeralPublicKey: string;
    publicKeyHash: string;
    transactionId: string;
  };
}
