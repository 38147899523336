import { catchError, map } from 'rxjs/operators';
import { from, throwError, Observable } from 'rxjs';
import { HttpHeaders, HttpResponse, HttpErrorResponse, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorMonitoringService } from '@libs/shared/utilities';
import { HTTP } from '@ionic-native/http/ngx';
import { PlatformService } from '@libs/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class NativeHttpService {
  constructor(private errorMonitoring: ErrorMonitoringService, private nativeHttp: HTTP, private platformService: PlatformService) {
    if (this.platformService.isIos()) {
      this.nativeHttp.setDataSerializer('utf8');
      this.nativeHttp.setHeader('*', 'Content-Type', 'application/json');
    } else {
      this.nativeHttp.setDataSerializer('json');
    }
  }

  /**
   * Make a HTTP request using Ionic native HTTP.
   */
  request(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (!this.platformService.isNative()) {
      return throwError({ message: 'Native HTTP is not available' });
    }
    const { url, method, headers, params, body } = req;
    const parsedHeaders = headers.keys().reduce((obj, key) => ({ ...obj, [key]: headers.get(key) }), {});
    const parsedParams = params.keys().reduce((obj, key) => {
      const value = params.get(key);
      return { ...obj, [key]: value == null ? '' : value.toString() };
    }, {});

    return from(
      this.nativeHttp.sendRequest(url, {
        method: <any>method,
        params: parsedParams,
        headers: parsedHeaders,
        data: this.platformService.isIos() ? <any>JSON.stringify(body) : body,
      })
    ).pipe(
      map((res) => {
        let result = {};
        try {
          if (res?.data) {
            result = JSON.parse(res.data);
          }
        } catch (e) {
          console.error('Native http error:', res);
          this.errorMonitoring.handleError(`Native HTTP: Error while parsing response data: ${res}`);
        }
        return new HttpResponse({ body: result, headers: new HttpHeaders(res.headers), url: res.url, status: res.status });
      }),
      catchError((err) => {
        const status = err.status === -2 ? 401 : err.status;
        let error = err.error;
        try {
          error = JSON.parse(error);
        } catch (e) {
          this.errorMonitoring.handleError(`Native HTTP: Error while parsing error '${error}': ${e}`);
        }
        return throwError(new HttpErrorResponse({ error, status: status }));
      })
    );
  }
}
