<ion-footer class="ion-no-border">
  <ion-toolbar>
    <svg class="left-corner" viewBox="0 0 24.68 24.768">
      <path
        d="M24.679,25H0V.233a24.857,24.857,0,0,0,1.965,9.5,24.917,24.917,0,0,0,5.358,7.947,24.917,24.917,0,0,0,7.947,5.358A24.832,24.832,0,0,0,24.679,25Z"
        transform="translate(0 -0.232)"
        attr.fill="{{ cornerOverlayFill }}"
      />
    </svg>
    <svg class="right-corner" viewBox="0 0 24.971 25">
      <path
        d="M375,25H350.03a24.84,24.84,0,0,0,9.7-1.965,24.917,24.917,0,0,0,7.947-5.358,24.918,24.918,0,0,0,5.358-7.947A24.842,24.842,0,0,0,375,0V25Z"
        transform="translate(-350.029)"
        attr.fill="{{ cornerOverlayFill }}"
      />
    </svg>
  </ion-toolbar>
</ion-footer>
