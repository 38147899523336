import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NotificationService } from '@libs/shared/utilities';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { LoginApiService } from '../../services/login-api.service';
import { forgotPasswordPageActions } from '../actions/forgot-password.actions';
import { loginApiActions } from '../actions/login-api.actions';

@Injectable()
export class ForgotPasswordEffects {
  constructor(
    private actions$: Actions,
    private loginApiService: LoginApiService,
    private navController: NavController,
    private notificationService: NotificationService
  ) {}

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPasswordPageActions.resetPassword),
      switchMap(({ email }) =>
        this.loginApiService.requestResetPasswordLink(email).pipe(
          map(() => loginApiActions.forgotPasswordSuccess({ email })),
          catchError((error) => of(loginApiActions.forgotPasswordFailure({ error })))
        )
      )
    )
  );

  showSuccessMessage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginApiActions.forgotPasswordSuccess),
        tap(({ email }) => this.notificationService.success({ message: `The reset password link has been sent to ${email}` }))
      ),
    { dispatch: false }
  );

  showErrorMessage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginApiActions.forgotPasswordFailure),
        tap(() => this.notificationService.danger({ message: `There was a problem sending the reset password link.  Please try again.` }))
      ),
    { dispatch: false }
  );

  navigateToLoginScreen$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginApiActions.forgotPasswordSuccess),
        tap(() => this.navController.navigateRoot(['onboard', 'login']))
      ),
    { dispatch: false }
  );
}
