import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeWhitespace',
})
export class RemoveWhitespacePipe implements PipeTransform {
  transform(value: string): unknown {
    return value.replace(/\s/g, '');
  }
}
