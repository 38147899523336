import { ApplePayPaymentContact } from './apple-pay-payment-contact.interface';
import { ApplePayPaymentToken } from './apple-pay-payment-token.interface';

export interface ApplePayPayment {
  /**
   * The encrypted token for an authorized payment.
   */
  token: ApplePayPaymentToken;

  /**
   * The billing contact selected by the user for this transaction.
   */
  billingContact?: ApplePayPaymentContact;

  /**
   * The shipping contact selected by the user for this transaction.
   */
  shippingContact?: ApplePayPaymentContact;
}
