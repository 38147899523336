import { OrderTypes } from '../../enums/order-types.enum';

export interface MerchantStore {
  name?: string;
  merchantId?: string;
  allowedOrderTypes: OrderTypes[];
  workingHours?: any;
  merchantStoreId?: string;
  isRestaurant?: boolean;
  isRetail?: boolean;
  isHotFrozen?: boolean;
  isClickCollect?: boolean;
  location: string;
  supportedPaymentMethods: string[];
  nextOpen: {
    isOpen: boolean;
    opensAt?: string;
  };
  waitTimeMinutes?: number;
  checkoutPublicKey?: string;
  customServiceChargeName: string;
  serviceCharge: boolean;
  serviceChargeAmount: number | null;
  isWaiterAdministeredServiceCharge: boolean;
  unlockServiceChargeCode: string | null;
  hasActiveDiscounts: boolean;
  requiresAgeConfirmation: boolean;
  lockServiceChargeThreshold: number;
  smsNotifications: boolean;
  allergyNotice: boolean;
}
