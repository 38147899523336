import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoveWhitespacePipe } from './remove-whitespace.pipe';

@NgModule({
  declarations: [RemoveWhitespacePipe],
  exports: [RemoveWhitespacePipe],
  imports: [CommonModule],
})
export class RemoveWhitespacePipeModule {}
