import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { take, map, filter } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { PlatformService } from '@libs/shared/utilities';
import { authenticationGuardActions } from '../+state/actions/authentication/authentication-guard.actions';
import { AuthenticationFacadeService } from '../services/authentication-facade/authentication-facade.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivateChild, CanActivate {
  constructor(private authenticationFacade: AuthenticationFacadeService, private platform: PlatformService) {}

  noAuthWebRoutes = [
    '/menu',
    '/main/payment/checkout',
    '/main/restaurant/order-confirm',
    '/main/payment/slots/',
    '/scanner/scanner/self-checkout',
  ];

  private guardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    const isWebNoAppFlow = !this.platform.isNative() && this.noAuthWebRoutes.some((r) => state.url.indexOf(r) === 0);

    return combineLatest([
      this.authenticationFacade.userInitialised(),
      this.authenticationFacade.userInitialisationError$,
      this.authenticationFacade.getUser(),
    ]).pipe(
      filter(([userInitialised, userInitialisationError]) => userInitialised || !!userInitialisationError || isWebNoAppFlow),
      take(1),
      map(([userInitialised, userInitialisationError, user]) => {
        if (userInitialised && user) {
          if (user.emailConfirmed) {
            return true;
          } else {
            this.authenticationFacade.dispatch(
              authenticationGuardActions.userEmailConfirmationRequired({ email: user.email, userId: user.userId })
            );
            return false;
          }
        } else if (isWebNoAppFlow) {
          return true;
        } else if (userInitialisationError) {
          if ((<HttpErrorResponse>userInitialisationError).status === 401) {
            this.authenticationFacade.dispatch(authenticationGuardActions.userNotAuthenticated());
          }
          return false;
        }
      })
    );
  };

  canActivate = this.guardFn;
  canActivateChild = this.guardFn;
}
