import { ApplePayLineItem } from './apple-pay-line-item.interface';
import { ApplePayPaymentContact } from './apple-pay-payment-contact.interface';
import { ApplePayShippingMethod } from './apple-pay-shipping-method.interface';

/**
 * Encapsulates a request for payment, including information about payment processing capabilities, the payment amount, and shipping information.
 */
export interface ApplePayPaymentRequest {
  /**
   * The merchant's two-letter ISO 3166 country code.
   */
  countryCode: string;

  /**
   * The three-letter ISO 4217 currency code for the payment.
   */
  currencyCode: string;

  /**
   * A set of line items that explain recurring payments and/or additional charges.
   */
  lineItems?: ApplePayLineItem[];

  /**
   * The payment capabilities supported by the merchant.
   * The value must at least contain ApplePayMerchantCapability.supports3DS.
   */
  merchantCapabilities: string[];

  /**
   * The payment networks supported by the merchant.
   */
  supportedNetworks: string[];

  /**
   * A line item representing the total for the payment.
   */
  total: ApplePayLineItem;

  /**
   * Billing contact information for the user.
   */
  billingContact?: ApplePayPaymentContact;

  /**
   * The billing information that you require from the user in order to process the transaction.
   */
  requiredBillingContactFields?: string[];

  /**
   * The shipping information that you require from the user in order to fulfill the order.
   */
  requiredShippingContactFields?: string[];

  /**
   * Shipping contact information for the user.
   */
  shippingContact?: ApplePayPaymentContact;

  /**
   * A set of shipping method objects that describe the available shipping methods.
   */
  shippingMethods?: ApplePayShippingMethod[];

  /**
   * How the items are to be shipped.
   */
  shippingType?: string;

  /**
   * Optional user-defined data.
   */
  applicationData?: string;
}
