import { FramesCardValidationEvent } from './frames-card-validation-event.interface';
import { FramesTokenizationSuccess } from './frames-tokenization-success.interface';

export interface Frames {
  init: ({ publicKey: string, style: framesInputStyles }) => void;
  submitCard: () => void;
  addEventHandler: (
    event: string,
    callback: (event: FramesCardValidationEvent | FramesTokenizationSuccess | boolean | Error) => void
  ) => void;
  addCardToken: (form: any, token: string) => void;
  enableSubmitForm: () => void;
  cardholder: {
    name: string;
    billingAddress: {
      zip: string;
    };
  };
  Events: {
    CARD_VALIDATION_CHANGED: string;
    FRAME_VALIDATION_CHANGED: string;
    CARD_SUBMITTED: string;
    CARD_TOKENIZED: string;
    CARD_TOKENIZATION_FAILED: string;
  };
}

export interface FramesInputStyles {
  base: {
    color: string;
  };
  hover: {
    color: string;
  };
  focus: {
    color: string;
  };
  invalid: {
    color: string;
  };
  placeholder: {
    base: {
      color: string;
    };
  };
}
