import { Inject, Injectable } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
import { CapacitorService } from 'libs/utility-native/src';
import { NativeWrapper } from 'libs/utility-native/src';
import { KEYBOARD } from '../../../tokens/keyboard.token';

@Injectable({
  providedIn: 'root',
})
export class KeyboardService extends NativeWrapper<typeof Keyboard> {
  constructor(capacitorService: CapacitorService, @Inject(KEYBOARD) keyboard: typeof Keyboard) {
    super('Keyboard', capacitorService, keyboard);
  }
}
