import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsumerAddonModalComponent } from './consumer-addon-modal.component';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  ChipComponentModule,
  ExchangeButtonComponentModule,
  ListItemActionComponentModule,
  LoadingModule,
  ModalComponentModule,
  StepperUiComponentModule,
  ToggleButtonComponentModule,
} from '@libs/shared/ui';

@NgModule({
  imports: [
    CommonModule,
    ModalComponentModule,
    ChipComponentModule,
    ListItemActionComponentModule,
    IonicModule,
    LoadingModule,
    StepperUiComponentModule,
    FontAwesomeModule,
    ToggleButtonComponentModule,
    ExchangeButtonComponentModule,
  ],
  declarations: [ConsumerAddonModalComponent],
  exports: [ConsumerAddonModalComponent],
})
export class ConsumerAddonComponentModule {}
