import { Pipe, PipeTransform } from '@angular/core';
import { TimeService } from '../../services/time.service';

@Pipe({
  name: 'seconds',
})
export class SecondsPipe implements PipeTransform {
  constructor(private timeService: TimeService) {}
  transform(seconds: number): string {
    return this.timeService.getDurationMessageFromSeconds(seconds);
  }
}
