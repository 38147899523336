import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'kody-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderTopComponent {
  @Input() title: string;

  constructor() {}
}
