import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'kody-form-toggle',
  templateUrl: './form-toggle.component.html',
  styleUrls: ['./form-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormToggleComponent {
  @Input() text: string;
  @Input() title: string;
  @Input() toggleControl: FormControl<boolean>;
}
