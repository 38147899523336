import { Inject, Injectable } from '@angular/core';
import { StatusBar } from '@capacitor/status-bar';
import { CapacitorService } from '../../../capacitor/services/capacitor.service';
import { STATUS_BAR } from '../../../tokens/statusbar.token';
import { NativeWrapper } from '../native-wrapper/native-wrapper';

@Injectable({
  providedIn: 'root',
})
export class StatusBarService extends NativeWrapper<typeof StatusBar> {
  constructor(capacitorService: CapacitorService, @Inject(STATUS_BAR) statusBar: typeof StatusBar) {
    super('StatusBar', capacitorService, statusBar);
  }
}
