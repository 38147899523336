import { PushNotifications } from '@capacitor/push-notifications';
import { Inject, Injectable } from '@angular/core';
import { PUSH_NOTIFICATIONS } from '../../tokens/push-notifications.token';
import { CapacitorService } from '../../../capacitor/services/capacitor.service';
import { NativeWrapper } from '../../../wrappers/services/native-wrapper/native-wrapper';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService extends NativeWrapper<typeof PushNotifications> {
  constructor(capacitorService: CapacitorService, @Inject(PUSH_NOTIFICATIONS) pushNotifications: typeof PushNotifications) {
    super('PushNotifications', capacitorService, pushNotifications);
  }
}
