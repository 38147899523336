import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpErrorResponse, HttpResponse, HttpEvent } from '@angular/common/http';
import { catchError, tap, take, map, timeout, switchMap, shareReplay } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PlatformService } from '@libs/shared/utilities';
import { authenticationInterceptorActions } from '../+state/actions/authentication/authentication-interceptor.actions';
import { SessionService } from '../services/session-controller/session-controller.service';
import { sessionActions } from '../+state/actions/session/session.actions';
import { AuthenticationFacadeService } from '../services/authentication-facade/authentication-facade.service';
import { DeviceService } from '@libs/utility-device';
import { NativeHttpService } from '@libs/utility-native';
import { authHeaders } from '@libs/shared/types';

/**
 * Handles authentication of HTTP requests.
 *
 * Requests can be authenticated in 2 different ways:
 *
 * 1) Access Token (Authorization header)
 * 2) Request signing (Signature Header)
 *
 * By default a request will be signed and given an Authorization header, but this
 * behaviour can be overriden by the client with the "X-no-auth-unsigned" or "X-no-auth-signed" headers.
 */
@Injectable()
export class AuthenticationHttpInterceptor {
  private readonly timeoutMillis = 15000;

  private registeringDevice$: Observable<any>;

  constructor(
    private sessionService: SessionService,
    private deviceService: DeviceService,
    private nativeHttpService: NativeHttpService,
    private authenticationFacade: AuthenticationFacadeService,
    private platformService: PlatformService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(authHeaders.unsignedHeaderKey)) {
      return this.handleRequest({
        next,
        request,
        signRequest: false,
      });
    } else if (request.headers.has(authHeaders.signedHeaderKey)) {
      return this.handleRequest({
        next,
        request,
        signRequest: true,
      });
    } else {
      // Get the current session - performs a refresh on the auth token if required.
      return this.sessionService.currentSession().pipe(
        take(1),
        switchMap((session) => {
          return this.handleRequest({
            next,
            request,
            signRequest: true,
            accessToken: session?.accessToken,
          });
        })
      );
    }
  }

  private handleRequest(config: HandleRequestArgs, retryOnFail = true): Observable<HttpEvent<any>> {
    const { next, request, signRequest, accessToken } = config;
    const request$: Observable<HttpRequest<any>> = of(request);

    return request$.pipe(
      map((req) => {
        let headers = req.headers;
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.delete(authHeaders.unsignedHeaderKey.toLowerCase());
        headers = headers.delete(authHeaders.signedHeaderKey.toLowerCase());
        if (accessToken) {
          headers = headers.delete('Authorization'); // Avoids issues from arising from auth header being set twice
          headers = headers.append('Authorization', `Bearer ${accessToken}`);
        }
        return req.clone({ headers });
      }),
      switchMap((req) => (signRequest ? this.deviceService.signRequest(req) : of(req))),
      // Signed requests are always done with Native HTTP on Android/iOS
      switchMap((req) => (this.platformService.isNative() && signRequest ? this.nativeHttpService.request(req) : next.handle(req))),
      tap(this.setSession),
      catchError((error: HttpErrorResponse) => this.handleErrorResponse(error, config, retryOnFail)),
      timeout(this.timeoutMillis)
    );
  }

  private setSession = (res: HttpResponse<any>) => {
    if (res instanceof HttpResponse) {
      const session = {
        accessTokenExp: res.headers.get('exp') ? parseInt(res.headers.get('exp')) : null,
        accessToken: res.headers.get('Authorization'),
        refreshToken: res.headers.get('refresh-token'),
      };
      if (session.accessToken || session.accessTokenExp || session.refreshToken) {
        this.authenticationFacade.dispatch(sessionActions.setSession({ payload: session }));
      }
    }
    return res;
  };

  private handleErrorResponse = (error: HttpErrorResponse, handleRequestArgs: HandleRequestArgs, retry: boolean) => {
    if (error?.status === 423 && retry) {
      // Try registering the device if it is not already in progress.
      if (!this.registeringDevice$) {
        this.registeringDevice$ = this.deviceService.registerDevice().pipe(
          take(1),
          tap(() => (this.registeringDevice$ = undefined)),
          shareReplay(1)
        );
      }

      // Retry the request once the device is registered
      return this.registeringDevice$.pipe(switchMap(() => this.handleRequest(handleRequestArgs, false)));
    }

    if (error.status === 423) {
      this.authenticationFacade.dispatch(authenticationInterceptorActions.deviceRegistrationError({ error }));
    } else if (error.status === 401) {
      this.authenticationFacade.dispatch(authenticationInterceptorActions.notAuthenticatedError({ error }));
    }
    return throwError(error);
  };
}

export const authenticationInterceptorProvider = [{ provide: HTTP_INTERCEPTORS, useClass: AuthenticationHttpInterceptor, multi: true }];

interface HandleRequestArgs {
  next: HttpHandler;
  request: HttpRequest<any>;
  signRequest?: boolean;
  accessToken?: string;
}
