import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ForgotPasswordEffects } from './effects/forgot-password.effects';
import { LoginApiEffects } from './effects/login-api.effects';
import { LoginEffects } from './effects/login.effects';
import { loginStateKey } from './models/login-state.model';
import { loginPageReducer } from './reducers/login-page.reducer';
import { loginReducer } from './reducers/login.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(loginStateKey, loginReducer),
    EffectsModule.forFeature([LoginApiEffects, LoginEffects, ForgotPasswordEffects]),
  ],
})
export class LoginStateModule {}
