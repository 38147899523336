import { IonInfiniteScroll } from '@ionic/angular';
import { Component, ChangeDetectionStrategy, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'kody-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsListComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input()
  loading: boolean;

  @Input()
  itemHeight: number;

  @Input()
  ghostRowCount = 5;

  @Input()
  itemsCurrentCount = 0;

  @Input()
  itemsTotalCount = 0;

  @Input()
  emptyListConfig: EmptyListConfig;

  @Output()
  loadMore = new EventEmitter<{ complete: () => void }>();

  rowNumbers: number[];

  onLoadMore = (): void => {
    if (this.itemsCurrentCount >= this.itemsTotalCount) {
      this.infiniteScroll.disabled = true;
      this.infiniteScroll.complete();
    } else {
      this.loadMore.emit({ complete: () => this.infiniteScroll.complete() });
    }
  };

  ngOnInit(): void {
    this.rowNumbers = Array.from(new Array(this.ghostRowCount).keys());
  }
}

export interface EmptyListConfig {
  title: string;
  message?: string;
  image?: string;
}
