/**
 * Defines a line item in a payment request - for example, total, tax, discount, or grand total.
 */
export interface ApplePayLineItem {
  /**
   * A short, localized description of the line item.
   */
  label: string;

  /**
   * The line item's amount.
   */
  amount: string;

  /**
   * A value that indicates if the line item is final or pending.
   */
  type?: string;
}
