import { OrderTypes, RestaurantOrder, StoreItem } from '@libs/shared/types';

export type OrderDetails = Pick<
  RestaurantOrder,
  | 'dateCreated'
  | 'items'
  | 'itemsCount'
  | 'merchantId'
  | 'merchantStoreId'
  | 'notes'
  | 'orderNumber'
  | 'restaurantOrderId'
  | 'serviceChargeAmount'
  | 'status'
  | 'tableNumber'
  | 'totalAmount'
  | 'transactionTotalAmount'
> & {
  isFullyRefunded: boolean;
  refundItems: StoreItem[] | null;
  timeSlotTime: string | null;
  type: OrderTypes;
  discountCode: string | null;
  discountFixedAmount: number | null;
  discountPercentage: number | null;
  collectedTime: Date | null;
  lastSmsNotificationTime: Date | null;
  nextSmsNotificationTime: Date | null;
};
