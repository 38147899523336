import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './login-page.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingModule } from '@libs/shared/ui';
import { LoginPageRoutingModule } from './login-page-routing.module';
import { LoginStateModule } from '../../+state/login-state.module';

@NgModule({
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FontAwesomeModule, LoadingModule, LoginPageRoutingModule, LoginStateModule],
  declarations: [LoginPageComponent],
  exports: [LoginPageComponent],
})
export class LoginPageComponentModule {}
