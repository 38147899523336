import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
@Component({
  selector: 'kodypay-timeline-horizontal',
  templateUrl: './timeline-horizontal.component.html',
  styleUrls: ['./timeline-horizontal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TimelineHorizontalComponent {
  @Input() items: string[];
  @Input() activeItem: string;
  @Input() animatedMessageHtml?: string;
  readonly faCheck = faCheck;

  getClass(items: string[], item: string): string {
    const activeIndex = items.indexOf(this.activeItem);

    if (items.indexOf(item) < activeIndex) {
      return 'check';
    }

    if (items.indexOf(item) === activeIndex) {
      return 'active';
    }
  }

  close(): void {
    if (this.animatedMessageHtml) {
      this.animatedMessageHtml = null;
    }
  }
}
