import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StoreFacade } from '@libs/shared/utilities';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { forgotPasswordPageSelectors } from '../../selectors/forgot-password-page.selectors';
import { loginSelectors } from '../../selectors/login-page.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoginFacadeService extends StoreFacade {
  constructor(protected store: Store) {
    super(store);
  }

  getUnauthorised(): Observable<string | HttpErrorResponse> {
    return this.store.select(loginSelectors.selectUnauthorisedState);
  }

  getLoginPageLoadingState(): Observable<boolean> {
    return this.store.select(loginSelectors.selectLoadingState);
  }

  getForgotPasswordPageLoading(): Observable<boolean> {
    return this.store.select(forgotPasswordPageSelectors.selectLoading);
  }
}
