import { AnimationBuilder } from '@ionic/angular';

export interface MultiPageModalOptions {
  props: {
    rootPage: any;
    rootParams?: any;
  };
  cssClass?: string;
  backdropDismiss?: boolean;
  presentingElement?: HTMLElement;
  leaveAnimation?: AnimationBuilder;
  enterAnimation?: AnimationBuilder;
}
