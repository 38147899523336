export interface BillingAddress {
  readonly phoneNumber?: string;
  readonly emailAddress: string;
  readonly name?: string;
  readonly addressLines?: string[];
  readonly postalCode?: string;
  readonly countryCode?: string;
  readonly locality?: string;
  readonly subLocality?: string;
  readonly administrativeArea?: string;
  readonly subAdministrativeArea?: string;
}
