import { ordersRoutes } from '@libs/shared/types';
import { NavController } from '@ionic/angular';
import { pushNotificationActions } from './../actions/push-notification.actions';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap, catchError, switchMap, map } from 'rxjs/operators';
import { NEVER, throwError, from } from 'rxjs';
import { ErrorMonitoringService } from '@libs/shared/utilities';
import { deviceActions } from '@libs/utility-device';
import { PushNotificationsService } from '../../services/push-notifications/push-notifications.service';

@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private navController: NavController,
    private pushNotifications: PushNotificationsService,
    private errorMonitoring: ErrorMonitoringService
  ) {}

  registerPushNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pushNotificationActions.registerPushNotifications),
      switchMap(() => {
        const permissionsPromise = this.pushNotifications.plugin.requestPermissions();
        const permissions$ = permissionsPromise !== undefined ? from(permissionsPromise) : NEVER;
        return permissions$.pipe(
          switchMap((result) => {
            if (result.receive === 'granted') {
              return from(this.pushNotifications.plugin.register());
            } else {
              return throwError(new Error('Failed to get push notification permissions'));
            }
          }),
          map(() => {
            return pushNotificationActions.registerPushNotificationsSuccess();
          }),
          catchError((err: any) => {
            this.errorMonitoring.handleError(err);
            return NEVER;
          })
        );
      })
    )
  );

  pingOnRegisterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pushNotificationActions.registerPushNotificationsSuccess),
      map(() => deviceActions.ping())
    )
  );

  openNewOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pushNotificationActions.newOrderReceivedOpened),
        tap((payload) => {
          this.navController.navigateRoot([ordersRoutes.list, ordersRoutes.details, payload?.restaurantOrderId]);
        })
      ),
    { dispatch: false }
  );

  openNewCcOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pushNotificationActions.newCcOrderReceivedOpened),
        tap(({ restaurantOrderId }) => {
          this.navController.navigateRoot([ordersRoutes.list, ordersRoutes.clickCollect, restaurantOrderId]);
        })
      ),
    { dispatch: false }
  );
}
