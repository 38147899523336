import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NavCornerOverlayComponent } from './nav-corner-overlay.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [NavCornerOverlayComponent],
  exports: [NavCornerOverlayComponent],
})
export class NavCornerOverlayComponentModule {}
