import { Injectable } from '@angular/core';

@Injectable()
export class EncoderService {
  encodeObjectToBase64(json: any): string {
    return btoa(JSON.stringify(json));
  }

  // Safe url base 64 encode
  // Replaces + with -, replaces / with _
  // https://en.wikipedia.org/wiki/Base64#Variants_summary_table
  encodeUrlSafeBase64(base64: string): string {
    return base64.replace(/\+/g, '-').replace(/\//g, '_');
  }
  encodeObjectToUrlSafeBase64(json: any): string {
    return this.encodeUrlSafeBase64(this.encodeObjectToBase64(json));
  }
}
