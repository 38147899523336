import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormToggleComponent } from './form-toggle.component';

@NgModule({
  declarations: [FormToggleComponent],
  imports: [CommonModule, IonicModule, ReactiveFormsModule],
  exports: [FormToggleComponent],
})
export class FormToggleComponentModule {}
