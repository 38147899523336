import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@libs/shared/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { LoginApiService } from '../../services/login-api.service';
import { loginApiActions } from '../actions/login-api.actions';
import { loginActions } from '../actions/login.actions';

@Injectable()
export class LoginEffects {
  constructor(private actions$: Actions, private loginApiService: LoginApiService) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.login),
      switchMap(({ email, password }) => {
        return this.loginApiService.login(email, password).pipe(
          map((user: User) => {
            if (user.roles?.length && user.roles[0]?.name === 'merchant_employee') {
              return loginApiActions.loginSuccess({ user });
            } else {
              return loginActions.incorrectUserRole();
            }
          }),
          catchError((error: HttpErrorResponse) => of(loginApiActions.loginFailure({ error })))
        );
      })
    )
  );
}
