import { ApplePayPaymentPass } from './apple-pay-payment-pass.interface';

/**
 * Contains information about an Apple Pay payment card.
 */
export interface ApplePayPaymentMethod {
  /**
   * A string, suitable for display, that describes the card.
   */
  displayName: string;

  /**
   * A string, suitable for display, that is the name of the payment network backing the card.
   * The value is one of the supported networks specified in the supported​Networks property of the Apple​Pay​Payment​Request.
   */
  network: string;

  /**
   * A value representing the card's type of payment.
   */
  type: string;

  /**
   * The payment pass object associated with the payment.
   */
  paymentPass: ApplePayPaymentPass;
}
