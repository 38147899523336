import { Injectable } from '@angular/core';
import { StorageService } from '../storage.service';
import { Storage } from '@ionic/storage-angular';

@Injectable()
// For web, Angular storage will use LocalStorage
export class AngularStorageService implements StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  getItem<T>(key: string): Promise<T> {
    return this._storage?.get(key);
  }

  setItem<T>(key: string, value: T): Promise<void> {
    return this._storage?.set(key, value);
  }

  removeItem(key: string): Promise<void> {
    return this._storage?.remove(key);
  }

  clearAll(): Promise<void> {
    return this._storage?.clear();
  }

  getKeys(): Promise<string[]> {
    return this._storage?.keys();
  }

  getItemsTotal(): Promise<number> {
    return this._storage?.length();
  }

  async appendItem(key: string, value: any): Promise<void> {
    if (!value || !key) {
      return;
    }
    if (!Array.isArray(value)) {
      value = Array.from(value);
    }
    const items: any[] = (await this.getItem(key)) || [];
    items.push(...value);
    return this._storage?.set(key, items);
  }
}
