import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kody-menu-checkout-button',
  templateUrl: './menu-checkout-button.component.html',
  styleUrls: ['./menu-checkout-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuCheckoutButtonComponent {
  @Input() isDisabled: boolean;
  @Input() itemsCount: number;
  @Output() checkout = new EventEmitter<void>();
}
