import { OrderTypes } from '../../enums/order-types.enum';
import { ItemGroup } from '../menu/item-group.interface';
import { CartItem } from './cart-item.interface';

export interface Cart {
  itemsMap?: { [id: string]: CartItem };
  orderType?: OrderTypes;
  eatingIn?: boolean;
  totalAmount?: number;
  itemGroups?: ItemGroup[];
  notes?: string;
  // Legacy props required by KodyPay app (remove once split is complete)
  totalSum?: number;
  itemsCount?: number;
  restaurantTable?: string;
  merchantStoreId?: string;
  orderId?: string; // TODO: Make required
}
