import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /** A signed eight-byte integer. The upper big integer values are greater than the max value for a JavaScript number. Therefore all big integers will be output as strings and not numbers. */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The day, does not include a time. */
  Date: any;
  /** A point in time as described by the [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone. */
  Datetime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The exact time of day, does not include the date. May or may not have a timezone offset. */
  Time: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};

export type AllMerchantItem = {
  readonly addonItems?: Maybe<Scalars['JSON']>;
  readonly barcode?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['BigInt']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly image?: Maybe<File>;
  readonly isClickCollect?: Maybe<Scalars['Boolean']>;
  readonly isCustom?: Maybe<Scalars['Boolean']>;
  readonly isEatIn?: Maybe<Scalars['Boolean']>;
  readonly isGlutenFree?: Maybe<Scalars['Boolean']>;
  readonly isOnOffer?: Maybe<Scalars['Boolean']>;
  readonly isTakeout?: Maybe<Scalars['Boolean']>;
  readonly isVegan?: Maybe<Scalars['Boolean']>;
  readonly isVegetarian?: Maybe<Scalars['Boolean']>;
  readonly itemId?: Maybe<Scalars['String']>;
  readonly itemStatusId?: Maybe<Scalars['UUID']>;
  readonly itemStatusName?: Maybe<Scalars['String']>;
  readonly menuCategoryId?: Maybe<Scalars['UUID']>;
  readonly menuCategoryOrderIndex?: Maybe<Scalars['Int']>;
  readonly merchantId?: Maybe<Scalars['UUID']>;
  readonly merchantItemId?: Maybe<Scalars['UUID']>;
  readonly merchantStoreIds?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  readonly name?: Maybe<Scalars['String']>;
  readonly newPrice?: Maybe<Scalars['BigFloat']>;
  readonly offerTypeId?: Maybe<Scalars['UUID']>;
  readonly price?: Maybe<Scalars['BigFloat']>;
  readonly statusId?: Maybe<Scalars['UUID']>;
  readonly weight?: Maybe<Scalars['String']>;
};

/** A filter to be used against `AllMerchantItem` object types. All fields are combined with a logical ‘and.’ */
export type AllMerchantItemFilter = {
  /** Filter by the object’s `addonItems` field. */
  readonly addonItems?: Maybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly AllMerchantItemFilter[]>;
  /** Filter by the object’s `barcode` field. */
  readonly barcode?: Maybe<StringFilter>;
  /** Filter by the object’s `count` field. */
  readonly count?: Maybe<BigIntFilter>;
  /** Filter by the object’s `description` field. */
  readonly description?: Maybe<StringFilter>;
  /** Filter by the object’s `image` field. */
  readonly image?: Maybe<FileFilter>;
  /** Filter by the object’s `isClickCollect` field. */
  readonly isClickCollect?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isCustom` field. */
  readonly isCustom?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isEatIn` field. */
  readonly isEatIn?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isGlutenFree` field. */
  readonly isGlutenFree?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isOnOffer` field. */
  readonly isOnOffer?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isTakeout` field. */
  readonly isTakeout?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isVegan` field. */
  readonly isVegan?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isVegetarian` field. */
  readonly isVegetarian?: Maybe<BooleanFilter>;
  /** Filter by the object’s `itemId` field. */
  readonly itemId?: Maybe<StringFilter>;
  /** Filter by the object’s `itemStatusId` field. */
  readonly itemStatusId?: Maybe<UuidFilter>;
  /** Filter by the object’s `itemStatusName` field. */
  readonly itemStatusName?: Maybe<StringFilter>;
  /** Filter by the object’s `menuCategoryId` field. */
  readonly menuCategoryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `menuCategoryOrderIndex` field. */
  readonly menuCategoryOrderIndex?: Maybe<IntFilter>;
  /** Filter by the object’s `merchantId` field. */
  readonly merchantId?: Maybe<UuidFilter>;
  /** Filter by the object’s `merchantItemId` field. */
  readonly merchantItemId?: Maybe<UuidFilter>;
  /** Filter by the object’s `merchantStoreIds` field. */
  readonly merchantStoreIds?: Maybe<UuidListFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Filter by the object’s `newPrice` field. */
  readonly newPrice?: Maybe<BigFloatFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<AllMerchantItemFilter>;
  /** Filter by the object’s `offerTypeId` field. */
  readonly offerTypeId?: Maybe<UuidFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly AllMerchantItemFilter[]>;
  /** Filter by the object’s `price` field. */
  readonly price?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `statusId` field. */
  readonly statusId?: Maybe<UuidFilter>;
  /** Filter by the object’s `weight` field. */
  readonly weight?: Maybe<StringFilter>;
};

/** A connection to a list of `AllMerchantItem` values. */
export type AllMerchantItemsConnection = {
  /** A list of edges which contains the `AllMerchantItem` and cursor to aid in pagination. */
  readonly edges: readonly AllMerchantItemsEdge[];
  /** A list of `AllMerchantItem` objects. */
  readonly nodes: readonly Maybe<AllMerchantItem>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `AllMerchantItem` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `AllMerchantItem` edge in the connection. */
export type AllMerchantItemsEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `AllMerchantItem` at the end of the edge. */
  readonly node?: Maybe<AllMerchantItem>;
};

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['BigFloat']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['BigFloat']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Included in the specified list. */
  readonly in?: Maybe<readonly Scalars['BigFloat'][]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['BigFloat']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<readonly Scalars['BigFloat'][]>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['BigInt']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  readonly in?: Maybe<readonly Scalars['BigInt'][]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['BigInt']>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<readonly Scalars['BigInt'][]>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  readonly in?: Maybe<readonly Scalars['Boolean'][]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<readonly Scalars['Boolean'][]>;
};

/** An input for mutations affecting `ChangePasswordRequestModel` */
export type ChangePasswordRequestModelInput = {
  readonly newPassword?: Maybe<Scalars['String']>;
  readonly password?: Maybe<Scalars['String']>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  readonly in?: Maybe<readonly Scalars['Datetime'][]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<readonly Scalars['Datetime'][]>;
};

/** All input for the `deleteCard` mutation. */
export type DeleteCardInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly userCardId?: Maybe<Scalars['UUID']>;
};

/** The output of our `deleteCard` mutation. */
export type DeleteCardPayload = {
  readonly boolean?: Maybe<Scalars['Boolean']>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
};

/** A `FeaturedStoresRecord` edge in the connection. */
export type FeaturedStoreEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `FeaturedStoresRecord` at the end of the edge. */
  readonly node?: Maybe<FeaturedStoresRecord>;
};

/** A connection to a list of `FeaturedStoresRecord` values. */
export type FeaturedStoresConnection = {
  /** A list of edges which contains the `FeaturedStoresRecord` and cursor to aid in pagination. */
  readonly edges: readonly FeaturedStoreEdge[];
  /** A list of `FeaturedStoresRecord` objects. */
  readonly nodes: readonly Maybe<FeaturedStoresRecord>[];
  /** The count of *all* `FeaturedStoresRecord` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** The return type of our `featuredStores` query. */
export type FeaturedStoresRecord = {
  readonly organization?: Maybe<Scalars['String']>;
  readonly stores?: Maybe<Scalars['JSON']>;
};

/** A filter to be used against `FeaturedStoresRecord` object types. All fields are combined with a logical ‘and.’ */
export type FeaturedStoresRecordFilter = {
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly FeaturedStoresRecordFilter[]>;
  /** Negates the expression. */
  readonly not?: Maybe<FeaturedStoresRecordFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly FeaturedStoresRecordFilter[]>;
  /** Filter by the object’s `organization` field. */
  readonly organization?: Maybe<StringFilter>;
  /** Filter by the object’s `stores` field. */
  readonly stores?: Maybe<JsonFilter>;
};

export type File = {
  readonly data?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly mimeType?: Maybe<Scalars['String']>;
};

/** A filter to be used against `File` object types. All fields are combined with a logical ‘and.’ */
export type FileFilter = {
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly FileFilter[]>;
  /** Filter by the object’s `fileName` field. */
  readonly fileName?: Maybe<StringFilter>;
  /** Filter by the object’s `mimeType` field. */
  readonly mimeType?: Maybe<StringFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<FileFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly FileFilter[]>;
};

/** An input for mutations affecting `File` */
export type FileInput = {
  readonly data?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly mimeType?: Maybe<Scalars['String']>;
};

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['Float']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['Float']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['Float']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['Float']>;
  /** Included in the specified list. */
  readonly in?: Maybe<readonly Scalars['Float'][]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['Float']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['Float']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['Float']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['Float']>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<readonly Scalars['Float'][]>;
};

/** All input for the `healthCheck` mutation. */
export type HealthCheckInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `healthCheck` mutation. */
export type HealthCheckPayload = {
  readonly boolean?: Maybe<Scalars['Boolean']>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  readonly in?: Maybe<readonly Scalars['Int'][]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['Int']>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<readonly Scalars['Int'][]>;
};

export type ItemAddonGql = {
  readonly merchantItemId?: Maybe<Scalars['UUID']>;
  readonly quantity?: Maybe<Scalars['Int']>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  readonly containedBy?: Maybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  readonly contains?: Maybe<Scalars['JSON']>;
  /** Contains all of the specified keys. */
  readonly containsAllKeys?: Maybe<readonly Scalars['String'][]>;
  /** Contains any of the specified keys. */
  readonly containsAnyKeys?: Maybe<readonly Scalars['String'][]>;
  /** Contains the specified key. */
  readonly containsKey?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['JSON']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Included in the specified list. */
  readonly in?: Maybe<readonly Scalars['JSON'][]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['JSON']>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<readonly Scalars['JSON'][]>;
};

/** All input for the `logout` mutation. */
export type LogoutInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly pushDeviceId?: Maybe<Scalars['String']>;
};

/** The output of our `logout` mutation. */
export type LogoutPayload = {
  readonly boolean?: Maybe<Scalars['Boolean']>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
};

export type MerchantItem = {
  readonly addonItems?: Maybe<Scalars['JSON']>;
  readonly barcode?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['BigInt']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly image?: Maybe<File>;
  readonly isClickCollect?: Maybe<Scalars['Boolean']>;
  readonly isCustom?: Maybe<Scalars['Boolean']>;
  readonly isEatIn?: Maybe<Scalars['Boolean']>;
  readonly isGlutenFree?: Maybe<Scalars['Boolean']>;
  readonly isOnOffer?: Maybe<Scalars['Boolean']>;
  readonly isTakeout?: Maybe<Scalars['Boolean']>;
  readonly isVegan?: Maybe<Scalars['Boolean']>;
  readonly isVegetarian?: Maybe<Scalars['Boolean']>;
  readonly itemId?: Maybe<Scalars['String']>;
  readonly menuCategoryId?: Maybe<Scalars['UUID']>;
  readonly menuCategoryOrderIndex?: Maybe<Scalars['Int']>;
  readonly merchantId?: Maybe<Scalars['UUID']>;
  readonly merchantItemId?: Maybe<Scalars['UUID']>;
  readonly merchantStoreIds?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  readonly name?: Maybe<Scalars['String']>;
  readonly newPrice?: Maybe<Scalars['BigFloat']>;
  readonly offerTypeId?: Maybe<Scalars['UUID']>;
  readonly price?: Maybe<Scalars['BigFloat']>;
  readonly statusId?: Maybe<Scalars['UUID']>;
  readonly weight?: Maybe<Scalars['String']>;
};

/** A condition to be used against `MerchantItem` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MerchantItemCondition = {
  /** Checks for equality with the object’s `addonItems` field. */
  readonly addonItems?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `barcode` field. */
  readonly barcode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `count` field. */
  readonly count?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `description` field. */
  readonly description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `image` field. */
  readonly image?: Maybe<FileInput>;
  /** Checks for equality with the object’s `isClickCollect` field. */
  readonly isClickCollect?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isCustom` field. */
  readonly isCustom?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isEatIn` field. */
  readonly isEatIn?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isGlutenFree` field. */
  readonly isGlutenFree?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isOnOffer` field. */
  readonly isOnOffer?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isTakeout` field. */
  readonly isTakeout?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isVegan` field. */
  readonly isVegan?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isVegetarian` field. */
  readonly isVegetarian?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `itemId` field. */
  readonly itemId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `menuCategoryId` field. */
  readonly menuCategoryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `menuCategoryOrderIndex` field. */
  readonly menuCategoryOrderIndex?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `merchantId` field. */
  readonly merchantId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `merchantItemId` field. */
  readonly merchantItemId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `merchantStoreIds` field. */
  readonly merchantStoreIds?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  /** Checks for equality with the object’s `name` field. */
  readonly name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `newPrice` field. */
  readonly newPrice?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `offerTypeId` field. */
  readonly offerTypeId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `price` field. */
  readonly price?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `statusId` field. */
  readonly statusId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `weight` field. */
  readonly weight?: Maybe<Scalars['String']>;
};

/** A filter to be used against `MerchantItem` object types. All fields are combined with a logical ‘and.’ */
export type MerchantItemFilter = {
  /** Filter by the object’s `addonItems` field. */
  readonly addonItems?: Maybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly MerchantItemFilter[]>;
  /** Filter by the object’s `barcode` field. */
  readonly barcode?: Maybe<StringFilter>;
  /** Filter by the object’s `count` field. */
  readonly count?: Maybe<BigIntFilter>;
  /** Filter by the object’s `description` field. */
  readonly description?: Maybe<StringFilter>;
  /** Filter by the object’s `image` field. */
  readonly image?: Maybe<FileFilter>;
  /** Filter by the object’s `isClickCollect` field. */
  readonly isClickCollect?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isCustom` field. */
  readonly isCustom?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isEatIn` field. */
  readonly isEatIn?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isGlutenFree` field. */
  readonly isGlutenFree?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isOnOffer` field. */
  readonly isOnOffer?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isTakeout` field. */
  readonly isTakeout?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isVegan` field. */
  readonly isVegan?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isVegetarian` field. */
  readonly isVegetarian?: Maybe<BooleanFilter>;
  /** Filter by the object’s `itemId` field. */
  readonly itemId?: Maybe<StringFilter>;
  /** Filter by the object’s `menuCategoryId` field. */
  readonly menuCategoryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `menuCategoryOrderIndex` field. */
  readonly menuCategoryOrderIndex?: Maybe<IntFilter>;
  /** Filter by the object’s `merchantId` field. */
  readonly merchantId?: Maybe<UuidFilter>;
  /** Filter by the object’s `merchantItemId` field. */
  readonly merchantItemId?: Maybe<UuidFilter>;
  /** Filter by the object’s `merchantStoreIds` field. */
  readonly merchantStoreIds?: Maybe<UuidListFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Filter by the object’s `newPrice` field. */
  readonly newPrice?: Maybe<BigFloatFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<MerchantItemFilter>;
  /** Filter by the object’s `offerTypeId` field. */
  readonly offerTypeId?: Maybe<UuidFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly MerchantItemFilter[]>;
  /** Filter by the object’s `price` field. */
  readonly price?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `statusId` field. */
  readonly statusId?: Maybe<UuidFilter>;
  /** Filter by the object’s `weight` field. */
  readonly weight?: Maybe<StringFilter>;
};

/** A connection to a list of `MerchantItem` values. */
export type MerchantItemsConnection = {
  /** A list of edges which contains the `MerchantItem` and cursor to aid in pagination. */
  readonly edges: readonly MerchantItemsEdge[];
  /** A list of `MerchantItem` objects. */
  readonly nodes: readonly Maybe<MerchantItem>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `MerchantItem` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `MerchantItem` edge in the connection. */
export type MerchantItemsEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `MerchantItem` at the end of the edge. */
  readonly node?: Maybe<MerchantItem>;
};

/** Methods to use when ordering `MerchantItem`. */
export enum MerchantItemsOrderBy {
  AddonItemsAsc = 'ADDON_ITEMS_ASC',
  AddonItemsDesc = 'ADDON_ITEMS_DESC',
  BarcodeAsc = 'BARCODE_ASC',
  BarcodeDesc = 'BARCODE_DESC',
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  ImageAsc = 'IMAGE_ASC',
  ImageDesc = 'IMAGE_DESC',
  IsClickCollectAsc = 'IS_CLICK_COLLECT_ASC',
  IsClickCollectDesc = 'IS_CLICK_COLLECT_DESC',
  IsCustomAsc = 'IS_CUSTOM_ASC',
  IsCustomDesc = 'IS_CUSTOM_DESC',
  IsEatInAsc = 'IS_EAT_IN_ASC',
  IsEatInDesc = 'IS_EAT_IN_DESC',
  IsGlutenFreeAsc = 'IS_GLUTEN_FREE_ASC',
  IsGlutenFreeDesc = 'IS_GLUTEN_FREE_DESC',
  IsOnOfferAsc = 'IS_ON_OFFER_ASC',
  IsOnOfferDesc = 'IS_ON_OFFER_DESC',
  IsTakeoutAsc = 'IS_TAKEOUT_ASC',
  IsTakeoutDesc = 'IS_TAKEOUT_DESC',
  IsVeganAsc = 'IS_VEGAN_ASC',
  IsVeganDesc = 'IS_VEGAN_DESC',
  IsVegetarianAsc = 'IS_VEGETARIAN_ASC',
  IsVegetarianDesc = 'IS_VEGETARIAN_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  MenuCategoryIdAsc = 'MENU_CATEGORY_ID_ASC',
  MenuCategoryIdDesc = 'MENU_CATEGORY_ID_DESC',
  MenuCategoryOrderIndexAsc = 'MENU_CATEGORY_ORDER_INDEX_ASC',
  MenuCategoryOrderIndexDesc = 'MENU_CATEGORY_ORDER_INDEX_DESC',
  MerchantIdAsc = 'MERCHANT_ID_ASC',
  MerchantIdDesc = 'MERCHANT_ID_DESC',
  MerchantItemIdAsc = 'MERCHANT_ITEM_ID_ASC',
  MerchantItemIdDesc = 'MERCHANT_ITEM_ID_DESC',
  MerchantStoreIdsAsc = 'MERCHANT_STORE_IDS_ASC',
  MerchantStoreIdsDesc = 'MERCHANT_STORE_IDS_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NewPriceAsc = 'NEW_PRICE_ASC',
  NewPriceDesc = 'NEW_PRICE_DESC',
  OfferTypeIdAsc = 'OFFER_TYPE_ID_ASC',
  OfferTypeIdDesc = 'OFFER_TYPE_ID_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  StatusIdAsc = 'STATUS_ID_ASC',
  StatusIdDesc = 'STATUS_ID_DESC',
  WeightAsc = 'WEIGHT_ASC',
  WeightDesc = 'WEIGHT_DESC',
}

export type MerchantStoreGql = {
  readonly applePayProviderId?: Maybe<Scalars['String']>;
  readonly applePayPublicKey?: Maybe<Scalars['String']>;
  readonly autoAcceptOrders?: Maybe<Scalars['Boolean']>;
  readonly autoCompleteOrders?: Maybe<Scalars['Boolean']>;
  readonly bagChargeNetPrice?: Maybe<Scalars['BigFloat']>;
  readonly bagChargePrice?: Maybe<Scalars['BigFloat']>;
  readonly enableBagCharge?: Maybe<Scalars['Boolean']>;
  readonly googlePayProviderId?: Maybe<Scalars['String']>;
  readonly googlePayPublicKey?: Maybe<Scalars['String']>;
  readonly isClickCollect?: Maybe<Scalars['Boolean']>;
  readonly isHotFrozen?: Maybe<Scalars['Boolean']>;
  readonly isRestaurant?: Maybe<Scalars['Boolean']>;
  readonly isRetail?: Maybe<Scalars['Boolean']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly merchantId?: Maybe<Scalars['UUID']>;
  readonly merchantStoreId?: Maybe<Scalars['UUID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly nextOpen?: Maybe<Scalars['JSON']>;
  readonly organization?: Maybe<Scalars['String']>;
  readonly serviceCharge?: Maybe<Scalars['Boolean']>;
  readonly serviceChargeAmount?: Maybe<Scalars['BigFloat']>;
  readonly supportedPaymentMethods?: Maybe<Scalars['JSON']>;
  readonly waitTimeMinutes?: Maybe<Scalars['Int']>;
  readonly workingHours?: Maybe<Scalars['JSON']>;
};

/** A condition to be used against `MerchantStore` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MerchantStoreCondition = {
  /** Checks for equality with the object’s `applePayProviderId` field. */
  readonly applePayProviderId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `applePayPublicKey` field. */
  readonly applePayPublicKey?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `autoAcceptOrders` field. */
  readonly autoAcceptOrders?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `autoCompleteOrders` field. */
  readonly autoCompleteOrders?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `bagChargeNetPrice` field. */
  readonly bagChargeNetPrice?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `bagChargePrice` field. */
  readonly bagChargePrice?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `enableBagCharge` field. */
  readonly enableBagCharge?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `googlePayProviderId` field. */
  readonly googlePayProviderId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `googlePayPublicKey` field. */
  readonly googlePayPublicKey?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isClickCollect` field. */
  readonly isClickCollect?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isHotFrozen` field. */
  readonly isHotFrozen?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isRestaurant` field. */
  readonly isRestaurant?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isRetail` field. */
  readonly isRetail?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `location` field. */
  readonly location?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `merchantId` field. */
  readonly merchantId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `merchantStoreId` field. */
  readonly merchantStoreId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  readonly name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `nextOpen` field. */
  readonly nextOpen?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `organization` field. */
  readonly organization?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `serviceCharge` field. */
  readonly serviceCharge?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `serviceChargeAmount` field. */
  readonly serviceChargeAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `supportedPaymentMethods` field. */
  readonly supportedPaymentMethods?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `waitTimeMinutes` field. */
  readonly waitTimeMinutes?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workingHours` field. */
  readonly workingHours?: Maybe<Scalars['JSON']>;
};

/** A filter to be used against `MerchantStore` object types. All fields are combined with a logical ‘and.’ */
export type MerchantStoreFilter = {
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly MerchantStoreFilter[]>;
  /** Filter by the object’s `applePayProviderId` field. */
  readonly applePayProviderId?: Maybe<StringFilter>;
  /** Filter by the object’s `applePayPublicKey` field. */
  readonly applePayPublicKey?: Maybe<StringFilter>;
  /** Filter by the object’s `autoAcceptOrders` field. */
  readonly autoAcceptOrders?: Maybe<BooleanFilter>;
  /** Filter by the object’s `autoCompleteOrders` field. */
  readonly autoCompleteOrders?: Maybe<BooleanFilter>;
  /** Filter by the object’s `bagChargeNetPrice` field. */
  readonly bagChargeNetPrice?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `bagChargePrice` field. */
  readonly bagChargePrice?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `enableBagCharge` field. */
  readonly enableBagCharge?: Maybe<BooleanFilter>;
  /** Filter by the object’s `googlePayProviderId` field. */
  readonly googlePayProviderId?: Maybe<StringFilter>;
  /** Filter by the object’s `googlePayPublicKey` field. */
  readonly googlePayPublicKey?: Maybe<StringFilter>;
  /** Filter by the object’s `isClickCollect` field. */
  readonly isClickCollect?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isHotFrozen` field. */
  readonly isHotFrozen?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isRestaurant` field. */
  readonly isRestaurant?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isRetail` field. */
  readonly isRetail?: Maybe<BooleanFilter>;
  /** Filter by the object’s `location` field. */
  readonly location?: Maybe<StringFilter>;
  /** Filter by the object’s `merchantId` field. */
  readonly merchantId?: Maybe<UuidFilter>;
  /** Filter by the object’s `merchantStoreId` field. */
  readonly merchantStoreId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Filter by the object’s `nextOpen` field. */
  readonly nextOpen?: Maybe<JsonFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<MerchantStoreFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly MerchantStoreFilter[]>;
  /** Filter by the object’s `organization` field. */
  readonly organization?: Maybe<StringFilter>;
  /** Filter by the object’s `serviceCharge` field. */
  readonly serviceCharge?: Maybe<BooleanFilter>;
  /** Filter by the object’s `serviceChargeAmount` field. */
  readonly serviceChargeAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `supportedPaymentMethods` field. */
  readonly supportedPaymentMethods?: Maybe<JsonFilter>;
  /** Filter by the object’s `waitTimeMinutes` field. */
  readonly waitTimeMinutes?: Maybe<IntFilter>;
  /** Filter by the object’s `workingHours` field. */
  readonly workingHours?: Maybe<JsonFilter>;
};

/** A connection to a list of `MerchantStore` values. */
export type MerchantStoresConnection = {
  /** A list of edges which contains the `MerchantStore` and cursor to aid in pagination. */
  readonly edges: readonly MerchantStoresEdge[];
  /** A list of `MerchantStore` objects. */
  readonly nodes: readonly Maybe<MerchantStoreGql>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `MerchantStore` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `MerchantStore` edge in the connection. */
export type MerchantStoresEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `MerchantStore` at the end of the edge. */
  readonly node?: Maybe<MerchantStoreGql>;
};

/** Methods to use when ordering `MerchantStore`. */
export enum MerchantStoresOrderBy {
  ApplePayProviderIdAsc = 'APPLE_PAY_PROVIDER_ID_ASC',
  ApplePayProviderIdDesc = 'APPLE_PAY_PROVIDER_ID_DESC',
  ApplePayPublicKeyAsc = 'APPLE_PAY_PUBLIC_KEY_ASC',
  ApplePayPublicKeyDesc = 'APPLE_PAY_PUBLIC_KEY_DESC',
  AutoAcceptOrdersAsc = 'AUTO_ACCEPT_ORDERS_ASC',
  AutoAcceptOrdersDesc = 'AUTO_ACCEPT_ORDERS_DESC',
  AutoCompleteOrdersAsc = 'AUTO_COMPLETE_ORDERS_ASC',
  AutoCompleteOrdersDesc = 'AUTO_COMPLETE_ORDERS_DESC',
  BagChargeNetPriceAsc = 'BAG_CHARGE_NET_PRICE_ASC',
  BagChargeNetPriceDesc = 'BAG_CHARGE_NET_PRICE_DESC',
  BagChargePriceAsc = 'BAG_CHARGE_PRICE_ASC',
  BagChargePriceDesc = 'BAG_CHARGE_PRICE_DESC',
  EnableBagChargeAsc = 'ENABLE_BAG_CHARGE_ASC',
  EnableBagChargeDesc = 'ENABLE_BAG_CHARGE_DESC',
  GooglePayProviderIdAsc = 'GOOGLE_PAY_PROVIDER_ID_ASC',
  GooglePayProviderIdDesc = 'GOOGLE_PAY_PROVIDER_ID_DESC',
  GooglePayPublicKeyAsc = 'GOOGLE_PAY_PUBLIC_KEY_ASC',
  GooglePayPublicKeyDesc = 'GOOGLE_PAY_PUBLIC_KEY_DESC',
  IsClickCollectAsc = 'IS_CLICK_COLLECT_ASC',
  IsClickCollectDesc = 'IS_CLICK_COLLECT_DESC',
  IsHotFrozenAsc = 'IS_HOT_FROZEN_ASC',
  IsHotFrozenDesc = 'IS_HOT_FROZEN_DESC',
  IsRestaurantAsc = 'IS_RESTAURANT_ASC',
  IsRestaurantDesc = 'IS_RESTAURANT_DESC',
  IsRetailAsc = 'IS_RETAIL_ASC',
  IsRetailDesc = 'IS_RETAIL_DESC',
  LocationAsc = 'LOCATION_ASC',
  LocationDesc = 'LOCATION_DESC',
  MerchantIdAsc = 'MERCHANT_ID_ASC',
  MerchantIdDesc = 'MERCHANT_ID_DESC',
  MerchantStoreIdAsc = 'MERCHANT_STORE_ID_ASC',
  MerchantStoreIdDesc = 'MERCHANT_STORE_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NextOpenAsc = 'NEXT_OPEN_ASC',
  NextOpenDesc = 'NEXT_OPEN_DESC',
  OrganizationAsc = 'ORGANIZATION_ASC',
  OrganizationDesc = 'ORGANIZATION_DESC',
  ServiceChargeAmountAsc = 'SERVICE_CHARGE_AMOUNT_ASC',
  ServiceChargeAmountDesc = 'SERVICE_CHARGE_AMOUNT_DESC',
  ServiceChargeAsc = 'SERVICE_CHARGE_ASC',
  ServiceChargeDesc = 'SERVICE_CHARGE_DESC',
  SupportedPaymentMethodsAsc = 'SUPPORTED_PAYMENT_METHODS_ASC',
  SupportedPaymentMethodsDesc = 'SUPPORTED_PAYMENT_METHODS_DESC',
  WaitTimeMinutesAsc = 'WAIT_TIME_MINUTES_ASC',
  WaitTimeMinutesDesc = 'WAIT_TIME_MINUTES_DESC',
  WorkingHoursAsc = 'WORKING_HOURS_ASC',
  WorkingHoursDesc = 'WORKING_HOURS_DESC',
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  readonly deleteCard?: Maybe<DeleteCardPayload>;
  readonly healthCheck?: Maybe<HealthCheckPayload>;
  readonly logout?: Maybe<LogoutPayload>;
  readonly nextOpen?: Maybe<NextOpenPayload>;
  readonly orderItemsWithRefunds?: Maybe<OrderItemsWithRefundsPayload>;
  readonly updatePassword?: Maybe<UpdatePasswordPayload>;
  readonly updateUser?: Maybe<UpdateUserPayload>;
  readonly updateUserPreferences?: Maybe<UpdateUserPreferencesPayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCardArgs = {
  input: DeleteCardInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationHealthCheckArgs = {
  input: HealthCheckInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationLogoutArgs = {
  input: LogoutInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationNextOpenArgs = {
  input: NextOpenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationOrderItemsWithRefundsArgs = {
  input: OrderItemsWithRefundsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserPreferencesArgs = {
  input: UpdateUserPreferencesInput;
};

/** All input for the `nextOpen` mutation. */
export type NextOpenInput = {
  readonly _currentDate?: Maybe<Scalars['Date']>;
  readonly _now?: Maybe<Scalars['Datetime']>;
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly closedEarly?: Maybe<Scalars['Boolean']>;
  readonly workingHours?: Maybe<Scalars['JSON']>;
};

/** The output of our `nextOpen` mutation. */
export type NextOpenPayload = {
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly json?: Maybe<Scalars['JSON']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  readonly id: Scalars['ID'];
};

export type OrderIdsWithPaymentProvider = {
  readonly paymentProvider?: Maybe<Scalars['String']>;
  readonly restaurantOrderId?: Maybe<Scalars['UUID']>;
};

/** A condition to be used against `OrderIdsWithPaymentProvider` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type OrderIdsWithPaymentProviderCondition = {
  /** Checks for equality with the object’s `paymentProvider` field. */
  readonly paymentProvider?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `restaurantOrderId` field. */
  readonly restaurantOrderId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `OrderIdsWithPaymentProvider` object types. All fields are combined with a logical ‘and.’ */
export type OrderIdsWithPaymentProviderFilter = {
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly OrderIdsWithPaymentProviderFilter[]>;
  /** Negates the expression. */
  readonly not?: Maybe<OrderIdsWithPaymentProviderFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly OrderIdsWithPaymentProviderFilter[]>;
  /** Filter by the object’s `paymentProvider` field. */
  readonly paymentProvider?: Maybe<StringFilter>;
  /** Filter by the object’s `restaurantOrderId` field. */
  readonly restaurantOrderId?: Maybe<UuidFilter>;
};

/** A connection to a list of `OrderIdsWithPaymentProvider` values. */
export type OrderIdsWithPaymentProvidersConnection = {
  /** A list of edges which contains the `OrderIdsWithPaymentProvider` and cursor to aid in pagination. */
  readonly edges: readonly OrderIdsWithPaymentProvidersEdge[];
  /** A list of `OrderIdsWithPaymentProvider` objects. */
  readonly nodes: readonly Maybe<OrderIdsWithPaymentProvider>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `OrderIdsWithPaymentProvider` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `OrderIdsWithPaymentProvider` edge in the connection. */
export type OrderIdsWithPaymentProvidersEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `OrderIdsWithPaymentProvider` at the end of the edge. */
  readonly node?: Maybe<OrderIdsWithPaymentProvider>;
};

/** Methods to use when ordering `OrderIdsWithPaymentProvider`. */
export enum OrderIdsWithPaymentProvidersOrderBy {
  Natural = 'NATURAL',
  PaymentProviderAsc = 'PAYMENT_PROVIDER_ASC',
  PaymentProviderDesc = 'PAYMENT_PROVIDER_DESC',
  RestaurantOrderIdAsc = 'RESTAURANT_ORDER_ID_ASC',
  RestaurantOrderIdDesc = 'RESTAURANT_ORDER_ID_DESC',
}

export type OrderItemsWithRefund = {
  readonly refundItems?: Maybe<readonly Maybe<RestaurantOrderItemModel>[]>;
  readonly restaurantOrderId?: Maybe<Scalars['UUID']>;
  readonly transactionItems?: Maybe<readonly Maybe<RestaurantOrderItemModel>[]>;
};

/** All input for the `orderItemsWithRefunds` mutation. */
export type OrderItemsWithRefundsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly restaurantOrderId?: Maybe<Scalars['UUID']>;
};

/** The output of our `orderItemsWithRefunds` mutation. */
export type OrderItemsWithRefundsPayload = {
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly orderItemsWithRefund?: Maybe<OrderItemsWithRefund>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
};

export type OrdersWithMerchantId = {
  readonly bagCharge?: Maybe<Scalars['Boolean']>;
  readonly bagChargeNetPrice?: Maybe<Scalars['BigFloat']>;
  readonly bagChargePrice?: Maybe<Scalars['BigFloat']>;
  readonly cardAuthorizationRequestId?: Maybe<Scalars['String']>;
  readonly clickCollectAlternative?: Maybe<Scalars['String']>;
  readonly creatorPushDeviceId?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly eatIn?: Maybe<Scalars['Boolean']>;
  readonly enableVatExemption?: Maybe<Scalars['Boolean']>;
  readonly hasPrinting?: Maybe<Scalars['Boolean']>;
  readonly isFullyRefunded?: Maybe<Scalars['Boolean']>;
  readonly isRead?: Maybe<Scalars['Boolean']>;
  readonly items?: Maybe<readonly Maybe<RestaurantOrderItemModel>[]>;
  readonly itemsCount?: Maybe<Scalars['Int']>;
  readonly manualDeduction?: Maybe<Scalars['BigFloat']>;
  readonly merchantId?: Maybe<Scalars['UUID']>;
  readonly merchantName?: Maybe<Scalars['String']>;
  readonly merchantStoreId?: Maybe<Scalars['UUID']>;
  readonly merchantStoreName?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly orderNumber?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['UUID']>;
  readonly paymentFailed?: Maybe<Scalars['Boolean']>;
  readonly paymentProvider?: Maybe<Scalars['String']>;
  readonly printerId?: Maybe<Scalars['String']>;
  readonly purged?: Maybe<Scalars['Boolean']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly restaurantOrderId?: Maybe<Scalars['UUID']>;
  readonly serviceChargeAmount?: Maybe<Scalars['BigFloat']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly tableNumber?: Maybe<Scalars['Int']>;
  readonly timeSlotDateTime?: Maybe<Scalars['Datetime']>;
  readonly timeSlotId?: Maybe<Scalars['String']>;
  readonly timeToComplete?: Maybe<Scalars['Int']>;
  readonly totalAmount?: Maybe<Scalars['BigFloat']>;
  readonly transactionId?: Maybe<Scalars['UUID']>;
  readonly transactionTotalAmount?: Maybe<Scalars['BigFloat']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly userCardId?: Maybe<Scalars['UUID']>;
  readonly userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `OrdersWithMerchantId` object types. All fields are combined with a logical ‘and.’ */
export type OrdersWithMerchantIdFilter = {
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly OrdersWithMerchantIdFilter[]>;
  /** Filter by the object’s `bagCharge` field. */
  readonly bagCharge?: Maybe<BooleanFilter>;
  /** Filter by the object’s `bagChargeNetPrice` field. */
  readonly bagChargeNetPrice?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `bagChargePrice` field. */
  readonly bagChargePrice?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `cardAuthorizationRequestId` field. */
  readonly cardAuthorizationRequestId?: Maybe<StringFilter>;
  /** Filter by the object’s `clickCollectAlternative` field. */
  readonly clickCollectAlternative?: Maybe<StringFilter>;
  /** Filter by the object’s `creatorPushDeviceId` field. */
  readonly creatorPushDeviceId?: Maybe<StringFilter>;
  /** Filter by the object’s `dateCreated` field. */
  readonly dateCreated?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `eatIn` field. */
  readonly eatIn?: Maybe<BooleanFilter>;
  /** Filter by the object’s `enableVatExemption` field. */
  readonly enableVatExemption?: Maybe<BooleanFilter>;
  /** Filter by the object’s `hasPrinting` field. */
  readonly hasPrinting?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isFullyRefunded` field. */
  readonly isFullyRefunded?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isRead` field. */
  readonly isRead?: Maybe<BooleanFilter>;
  /** Filter by the object’s `itemsCount` field. */
  readonly itemsCount?: Maybe<IntFilter>;
  /** Filter by the object’s `manualDeduction` field. */
  readonly manualDeduction?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `merchantId` field. */
  readonly merchantId?: Maybe<UuidFilter>;
  /** Filter by the object’s `merchantName` field. */
  readonly merchantName?: Maybe<StringFilter>;
  /** Filter by the object’s `merchantStoreId` field. */
  readonly merchantStoreId?: Maybe<UuidFilter>;
  /** Filter by the object’s `merchantStoreName` field. */
  readonly merchantStoreName?: Maybe<StringFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<OrdersWithMerchantIdFilter>;
  /** Filter by the object’s `notes` field. */
  readonly notes?: Maybe<StringFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly OrdersWithMerchantIdFilter[]>;
  /** Filter by the object’s `orderNumber` field. */
  readonly orderNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `organizationId` field. */
  readonly organizationId?: Maybe<UuidFilter>;
  /** Filter by the object’s `paymentFailed` field. */
  readonly paymentFailed?: Maybe<BooleanFilter>;
  /** Filter by the object’s `paymentProvider` field. */
  readonly paymentProvider?: Maybe<StringFilter>;
  /** Filter by the object’s `printerId` field. */
  readonly printerId?: Maybe<StringFilter>;
  /** Filter by the object’s `purged` field. */
  readonly purged?: Maybe<BooleanFilter>;
  /** Filter by the object’s `requestId` field. */
  readonly requestId?: Maybe<StringFilter>;
  /** Filter by the object’s `restaurantOrderId` field. */
  readonly restaurantOrderId?: Maybe<UuidFilter>;
  /** Filter by the object’s `serviceChargeAmount` field. */
  readonly serviceChargeAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `status` field. */
  readonly status?: Maybe<StringFilter>;
  /** Filter by the object’s `tableNumber` field. */
  readonly tableNumber?: Maybe<IntFilter>;
  /** Filter by the object’s `timeSlotDateTime` field. */
  readonly timeSlotDateTime?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `timeSlotId` field. */
  readonly timeSlotId?: Maybe<StringFilter>;
  /** Filter by the object’s `timeToComplete` field. */
  readonly timeToComplete?: Maybe<IntFilter>;
  /** Filter by the object’s `totalAmount` field. */
  readonly totalAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `transactionId` field. */
  readonly transactionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `transactionTotalAmount` field. */
  readonly transactionTotalAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `type` field. */
  readonly type?: Maybe<StringFilter>;
  /** Filter by the object’s `userCardId` field. */
  readonly userCardId?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  readonly userId?: Maybe<UuidFilter>;
};

/** A connection to a list of `OrdersWithMerchantId` values. */
export type OrdersWithMerchantIdsConnection = {
  /** A list of edges which contains the `OrdersWithMerchantId` and cursor to aid in pagination. */
  readonly edges: readonly OrdersWithMerchantIdsEdge[];
  /** A list of `OrdersWithMerchantId` objects. */
  readonly nodes: readonly Maybe<OrdersWithMerchantId>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `OrdersWithMerchantId` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `OrdersWithMerchantId` edge in the connection. */
export type OrdersWithMerchantIdsEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `OrdersWithMerchantId` at the end of the edge. */
  readonly node?: Maybe<OrdersWithMerchantId>;
};

/** Methods to use when ordering `OrdersWithMerchantId`. */
export enum OrdersWithMerchantIdsOrderBy {
  BagChargeAsc = 'BAG_CHARGE_ASC',
  BagChargeDesc = 'BAG_CHARGE_DESC',
  BagChargeNetPriceAsc = 'BAG_CHARGE_NET_PRICE_ASC',
  BagChargeNetPriceDesc = 'BAG_CHARGE_NET_PRICE_DESC',
  BagChargePriceAsc = 'BAG_CHARGE_PRICE_ASC',
  BagChargePriceDesc = 'BAG_CHARGE_PRICE_DESC',
  CardAuthorizationRequestIdAsc = 'CARD_AUTHORIZATION_REQUEST_ID_ASC',
  CardAuthorizationRequestIdDesc = 'CARD_AUTHORIZATION_REQUEST_ID_DESC',
  ClickCollectAlternativeAsc = 'CLICK_COLLECT_ALTERNATIVE_ASC',
  ClickCollectAlternativeDesc = 'CLICK_COLLECT_ALTERNATIVE_DESC',
  CreatorPushDeviceIdAsc = 'CREATOR_PUSH_DEVICE_ID_ASC',
  CreatorPushDeviceIdDesc = 'CREATOR_PUSH_DEVICE_ID_DESC',
  DateCreatedAsc = 'DATE_CREATED_ASC',
  DateCreatedDesc = 'DATE_CREATED_DESC',
  EatInAsc = 'EAT_IN_ASC',
  EatInDesc = 'EAT_IN_DESC',
  EnableVatExemptionAsc = 'ENABLE_VAT_EXEMPTION_ASC',
  EnableVatExemptionDesc = 'ENABLE_VAT_EXEMPTION_DESC',
  HasPrintingAsc = 'HAS_PRINTING_ASC',
  HasPrintingDesc = 'HAS_PRINTING_DESC',
  IsFullyRefundedAsc = 'IS_FULLY_REFUNDED_ASC',
  IsFullyRefundedDesc = 'IS_FULLY_REFUNDED_DESC',
  IsReadAsc = 'IS_READ_ASC',
  IsReadDesc = 'IS_READ_DESC',
  ItemsAsc = 'ITEMS_ASC',
  ItemsCountAsc = 'ITEMS_COUNT_ASC',
  ItemsCountDesc = 'ITEMS_COUNT_DESC',
  ItemsDesc = 'ITEMS_DESC',
  ManualDeductionAsc = 'MANUAL_DEDUCTION_ASC',
  ManualDeductionDesc = 'MANUAL_DEDUCTION_DESC',
  MerchantIdAsc = 'MERCHANT_ID_ASC',
  MerchantIdDesc = 'MERCHANT_ID_DESC',
  MerchantNameAsc = 'MERCHANT_NAME_ASC',
  MerchantNameDesc = 'MERCHANT_NAME_DESC',
  MerchantStoreIdAsc = 'MERCHANT_STORE_ID_ASC',
  MerchantStoreIdDesc = 'MERCHANT_STORE_ID_DESC',
  MerchantStoreNameAsc = 'MERCHANT_STORE_NAME_ASC',
  MerchantStoreNameDesc = 'MERCHANT_STORE_NAME_DESC',
  Natural = 'NATURAL',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  OrderNumberAsc = 'ORDER_NUMBER_ASC',
  OrderNumberDesc = 'ORDER_NUMBER_DESC',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PaymentFailedAsc = 'PAYMENT_FAILED_ASC',
  PaymentFailedDesc = 'PAYMENT_FAILED_DESC',
  PaymentProviderAsc = 'PAYMENT_PROVIDER_ASC',
  PaymentProviderDesc = 'PAYMENT_PROVIDER_DESC',
  PrinterIdAsc = 'PRINTER_ID_ASC',
  PrinterIdDesc = 'PRINTER_ID_DESC',
  PurgedAsc = 'PURGED_ASC',
  PurgedDesc = 'PURGED_DESC',
  RequestIdAsc = 'REQUEST_ID_ASC',
  RequestIdDesc = 'REQUEST_ID_DESC',
  RestaurantOrderIdAsc = 'RESTAURANT_ORDER_ID_ASC',
  RestaurantOrderIdDesc = 'RESTAURANT_ORDER_ID_DESC',
  ServiceChargeAmountAsc = 'SERVICE_CHARGE_AMOUNT_ASC',
  ServiceChargeAmountDesc = 'SERVICE_CHARGE_AMOUNT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TableNumberAsc = 'TABLE_NUMBER_ASC',
  TableNumberDesc = 'TABLE_NUMBER_DESC',
  TimeSlotDateTimeAsc = 'TIME_SLOT_DATE_TIME_ASC',
  TimeSlotDateTimeDesc = 'TIME_SLOT_DATE_TIME_DESC',
  TimeSlotIdAsc = 'TIME_SLOT_ID_ASC',
  TimeSlotIdDesc = 'TIME_SLOT_ID_DESC',
  TimeToCompleteAsc = 'TIME_TO_COMPLETE_ASC',
  TimeToCompleteDesc = 'TIME_TO_COMPLETE_DESC',
  TotalAmountAsc = 'TOTAL_AMOUNT_ASC',
  TotalAmountDesc = 'TOTAL_AMOUNT_DESC',
  TransactionIdAsc = 'TRANSACTION_ID_ASC',
  TransactionIdDesc = 'TRANSACTION_ID_DESC',
  TransactionTotalAmountAsc = 'TRANSACTION_TOTAL_AMOUNT_ASC',
  TransactionTotalAmountDesc = 'TRANSACTION_TOTAL_AMOUNT_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UserCardIdAsc = 'USER_CARD_ID_ASC',
  UserCardIdDesc = 'USER_CARD_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars['Cursor']>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  /** Reads and enables pagination through a set of `AllMerchantItem`. */
  readonly allConfigurableMerchantItems: AllMerchantItemsConnection;
  /** Reads and enables pagination through a set of `MerchantItem`. */
  readonly allMerchantItems?: Maybe<MerchantItemsConnection>;
  /** Reads and enables pagination through a set of `MerchantStore`. */
  readonly allMerchantStores?: Maybe<MerchantStoresConnection>;
  /** Reads and enables pagination through a set of `OrderIdsWithPaymentProvider`. */
  readonly allOrderIdsWithPaymentProviders?: Maybe<OrderIdsWithPaymentProvidersConnection>;
  /** Reads and enables pagination through a set of `SalesVolThisMonth`. */
  readonly allSalesVolThisMonths?: Maybe<SalesVolThisMonthsConnection>;
  /** Reads and enables pagination through a set of `SalesVolThisWeek`. */
  readonly allSalesVolThisWeeks?: Maybe<SalesVolThisWeeksConnection>;
  /** Reads and enables pagination through a set of `SalesVolumeMerchantsToday`. */
  readonly allSalesVolumeMerchantsTodays?: Maybe<SalesVolumeMerchantsTodaysConnection>;
  /** Reads and enables pagination through a set of `SalesVolumesDailyByOrganization`. */
  readonly allSalesVolumesDailyByOrganizations?: Maybe<SalesVolumesDailyByOrganizationsConnection>;
  /** Reads and enables pagination through a set of `SalesVolumesMonthly`. */
  readonly allSalesVolumesMonthlies?: Maybe<SalesVolumesMonthliesConnection>;
  /** Reads and enables pagination through a set of `SalesVolumesWeeklyByOrganization`. */
  readonly allSalesVolumesWeeklyByOrganizations?: Maybe<SalesVolumesWeeklyByOrganizationsConnection>;
  /** Reads and enables pagination through a set of `TransactionDataThisWeek`. */
  readonly allTransactionDataThisWeeks?: Maybe<TransactionDataThisWeeksConnection>;
  /** Reads and enables pagination through a set of `RestaurantMenuCategoryModel`. */
  readonly configurableMenuCategoriesByMerchantStoreId: RestaurantMenuCategoryModelsConnection;
  readonly featuredStores: FeaturedStoresConnection;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  readonly id: Scalars['ID'];
  readonly isBarcode?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `AllMerchantItem`. */
  readonly merchantItemsByStoreId: AllMerchantItemsConnection;
  /** Reads and enables pagination through a set of `Transaction`. */
  readonly merchantTransactions: TransactionsConnection;
  /** Fetches an object given its globally unique `ID`. */
  readonly node?: Maybe<Node>;
  /** Reads and enables pagination through a set of `OrdersWithMerchantId`. */
  readonly ordersByStoreId: OrdersWithMerchantIdsConnection;
  /** Reads and enables pagination through a set of `OrdersWithMerchantId`. */
  readonly ordersWithMerchantId: OrdersWithMerchantIdsConnection;
  readonly outstandingManualPaymentRequest?: Maybe<OrderIdsWithPaymentProvider>;
  /** Exposes the root query type nested one level down. This is helpful for Relay 1 which can only query top level fields if they are in a particular form. */
  readonly query: Query;
  /** Reads and enables pagination through a set of `MerchantStore`. */
  readonly recentStores: MerchantStoresConnection;
  /** Reads and enables pagination through a set of `RestaurantMenuCategoryModel`. */
  readonly restaurantMenuCategoriesByMerchantStoreId: RestaurantMenuCategoryModelsConnection;
  /** Reads and enables pagination through a set of `MerchantItem`. */
  readonly retailItems: MerchantItemsConnection;
  /** Reads and enables pagination through a set of `StoreTimeSlot`. */
  readonly timeSlotsByStoreId: StoreTimeSlotsConnection;
  /** Reads and enables pagination through a set of `UserCard`. */
  readonly userCards: UserCardsConnection;
  /** Reads and enables pagination through a set of `Transaction`. */
  readonly userTransactions: TransactionsConnection;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllConfigurableMerchantItemsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<AllMerchantItemFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllMerchantItemsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<MerchantItemCondition>;
  filter?: Maybe<MerchantItemFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly MerchantItemsOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllMerchantStoresArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<MerchantStoreCondition>;
  filter?: Maybe<MerchantStoreFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly MerchantStoresOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllOrderIdsWithPaymentProvidersArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<OrderIdsWithPaymentProviderCondition>;
  filter?: Maybe<OrderIdsWithPaymentProviderFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly OrderIdsWithPaymentProvidersOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSalesVolThisMonthsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SalesVolThisMonthCondition>;
  filter?: Maybe<SalesVolThisMonthFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly SalesVolThisMonthsOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSalesVolThisWeeksArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SalesVolThisWeekCondition>;
  filter?: Maybe<SalesVolThisWeekFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly SalesVolThisWeeksOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSalesVolumeMerchantsTodaysArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SalesVolumeMerchantsTodayCondition>;
  filter?: Maybe<SalesVolumeMerchantsTodayFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly SalesVolumeMerchantsTodaysOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSalesVolumesDailyByOrganizationsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SalesVolumesDailyByOrganizationCondition>;
  filter?: Maybe<SalesVolumesDailyByOrganizationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly SalesVolumesDailyByOrganizationsOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSalesVolumesMonthliesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SalesVolumesMonthlyCondition>;
  filter?: Maybe<SalesVolumesMonthlyFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly SalesVolumesMonthliesOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSalesVolumesWeeklyByOrganizationsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SalesVolumesWeeklyByOrganizationCondition>;
  filter?: Maybe<SalesVolumesWeeklyByOrganizationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly SalesVolumesWeeklyByOrganizationsOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTransactionDataThisWeeksArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<TransactionDataThisWeekCondition>;
  filter?: Maybe<TransactionDataThisWeekFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly TransactionDataThisWeeksOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryConfigurableMenuCategoriesByMerchantStoreIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<RestaurantMenuCategoryModelFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  merchantStoreId?: Maybe<Scalars['UUID']>;
  offset?: Maybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeaturedStoresArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<FeaturedStoresRecordFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryIsBarcodeArgs = {
  inputText?: Maybe<Scalars['String']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMerchantItemsByStoreIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<AllMerchantItemFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  merchantStoreId?: Maybe<Scalars['UUID']>;
  offset?: Maybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMerchantTransactionsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<TransactionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['UUID']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  id: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrdersByStoreIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<OrdersWithMerchantIdFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  merchantStoreId?: Maybe<Scalars['UUID']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly OrdersWithMerchantIdsOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryOrdersWithMerchantIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<OrdersWithMerchantIdFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<readonly OrdersWithMerchantIdsOrderBy[]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryRecentStoresArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<MerchantStoreFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryRestaurantMenuCategoriesByMerchantStoreIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<RestaurantMenuCategoryModelFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  merchantStoreId?: Maybe<Scalars['UUID']>;
  offset?: Maybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryRetailItemsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<MerchantItemFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  merchant?: Maybe<Scalars['UUID']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTimeSlotsByStoreIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<StoreTimeSlotFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  merchantStoreId?: Maybe<Scalars['UUID']>;
  offset?: Maybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserCardsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<UserCardFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserTransactionsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<TransactionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type RestaurantMenuCategoryModel = {
  readonly merchantId?: Maybe<Scalars['UUID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly restaurantMenuCategoryId?: Maybe<Scalars['UUID']>;
  readonly statusId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `RestaurantMenuCategoryModel` object types. All fields are combined with a logical ‘and.’ */
export type RestaurantMenuCategoryModelFilter = {
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly RestaurantMenuCategoryModelFilter[]>;
  /** Filter by the object’s `merchantId` field. */
  readonly merchantId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<RestaurantMenuCategoryModelFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly RestaurantMenuCategoryModelFilter[]>;
  /** Filter by the object’s `restaurantMenuCategoryId` field. */
  readonly restaurantMenuCategoryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `statusId` field. */
  readonly statusId?: Maybe<UuidFilter>;
};

/** A connection to a list of `RestaurantMenuCategoryModel` values. */
export type RestaurantMenuCategoryModelsConnection = {
  /** A list of edges which contains the `RestaurantMenuCategoryModel` and cursor to aid in pagination. */
  readonly edges: readonly RestaurantMenuCategoryModelsEdge[];
  /** A list of `RestaurantMenuCategoryModel` objects. */
  readonly nodes: readonly Maybe<RestaurantMenuCategoryModel>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `RestaurantMenuCategoryModel` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `RestaurantMenuCategoryModel` edge in the connection. */
export type RestaurantMenuCategoryModelsEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `RestaurantMenuCategoryModel` at the end of the edge. */
  readonly node?: Maybe<RestaurantMenuCategoryModel>;
};

export type RestaurantOrderItemModel = {
  readonly addonItems?: Maybe<readonly Maybe<ItemAddonGql>[]>;
  readonly alwaysVat?: Maybe<Scalars['Boolean']>;
  readonly barcode?: Maybe<Scalars['String']>;
  readonly collectStatus?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['BigInt']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly isCustom?: Maybe<Scalars['Boolean']>;
  readonly isEatIn?: Maybe<Scalars['Boolean']>;
  readonly isGlutenFree?: Maybe<Scalars['Boolean']>;
  readonly isOnOffer?: Maybe<Scalars['Boolean']>;
  readonly isTakeout?: Maybe<Scalars['Boolean']>;
  readonly isVegan?: Maybe<Scalars['Boolean']>;
  readonly isVegetarian?: Maybe<Scalars['Boolean']>;
  readonly itemId?: Maybe<Scalars['String']>;
  readonly merchantId?: Maybe<Scalars['UUID']>;
  readonly merchantItemId?: Maybe<Scalars['UUID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly netPrice?: Maybe<Scalars['BigFloat']>;
  readonly newPrice?: Maybe<Scalars['BigFloat']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly offerTypeId?: Maybe<Scalars['UUID']>;
  readonly parentItemId?: Maybe<Scalars['UUID']>;
  readonly parentRestaurantOrderItemId?: Maybe<Scalars['UUID']>;
  readonly price?: Maybe<Scalars['BigFloat']>;
  readonly quantity?: Maybe<Scalars['Int']>;
  readonly restaurantOrderItemId?: Maybe<Scalars['UUID']>;
  readonly statusId?: Maybe<Scalars['UUID']>;
  readonly vatType?: Maybe<Scalars['String']>;
  readonly weight?: Maybe<Scalars['String']>;
};

export type SalesVolThisMonth = {
  readonly amount?: Maybe<Scalars['BigFloat']>;
  readonly delta?: Maybe<Scalars['BigFloat']>;
  readonly merchantId?: Maybe<Scalars['UUID']>;
  readonly merchantStoreId?: Maybe<Scalars['UUID']>;
  readonly month?: Maybe<Scalars['Float']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly year?: Maybe<Scalars['Float']>;
};

/** A condition to be used against `SalesVolThisMonth` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SalesVolThisMonthCondition = {
  /** Checks for equality with the object’s `amount` field. */
  readonly amount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `delta` field. */
  readonly delta?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `merchantId` field. */
  readonly merchantId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `merchantStoreId` field. */
  readonly merchantStoreId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `month` field. */
  readonly month?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `name` field. */
  readonly name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `year` field. */
  readonly year?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `SalesVolThisMonth` object types. All fields are combined with a logical ‘and.’ */
export type SalesVolThisMonthFilter = {
  /** Filter by the object’s `amount` field. */
  readonly amount?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly SalesVolThisMonthFilter[]>;
  /** Filter by the object’s `delta` field. */
  readonly delta?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `merchantId` field. */
  readonly merchantId?: Maybe<UuidFilter>;
  /** Filter by the object’s `merchantStoreId` field. */
  readonly merchantStoreId?: Maybe<UuidFilter>;
  /** Filter by the object’s `month` field. */
  readonly month?: Maybe<FloatFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<SalesVolThisMonthFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly SalesVolThisMonthFilter[]>;
  /** Filter by the object’s `year` field. */
  readonly year?: Maybe<FloatFilter>;
};

/** A connection to a list of `SalesVolThisMonth` values. */
export type SalesVolThisMonthsConnection = {
  /** A list of edges which contains the `SalesVolThisMonth` and cursor to aid in pagination. */
  readonly edges: readonly SalesVolThisMonthsEdge[];
  /** A list of `SalesVolThisMonth` objects. */
  readonly nodes: readonly Maybe<SalesVolThisMonth>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `SalesVolThisMonth` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `SalesVolThisMonth` edge in the connection. */
export type SalesVolThisMonthsEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `SalesVolThisMonth` at the end of the edge. */
  readonly node?: Maybe<SalesVolThisMonth>;
};

/** Methods to use when ordering `SalesVolThisMonth`. */
export enum SalesVolThisMonthsOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  DeltaAsc = 'DELTA_ASC',
  DeltaDesc = 'DELTA_DESC',
  MerchantIdAsc = 'MERCHANT_ID_ASC',
  MerchantIdDesc = 'MERCHANT_ID_DESC',
  MerchantStoreIdAsc = 'MERCHANT_STORE_ID_ASC',
  MerchantStoreIdDesc = 'MERCHANT_STORE_ID_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
}

export type SalesVolThisWeek = {
  readonly amount?: Maybe<Scalars['BigFloat']>;
  readonly delta?: Maybe<Scalars['BigFloat']>;
  readonly merchantId?: Maybe<Scalars['UUID']>;
  readonly month?: Maybe<Scalars['Float']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['UUID']>;
  readonly week?: Maybe<Scalars['Float']>;
  readonly year?: Maybe<Scalars['Float']>;
};

/** A condition to be used against `SalesVolThisWeek` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SalesVolThisWeekCondition = {
  /** Checks for equality with the object’s `amount` field. */
  readonly amount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `delta` field. */
  readonly delta?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `merchantId` field. */
  readonly merchantId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `month` field. */
  readonly month?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `name` field. */
  readonly name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `organizationId` field. */
  readonly organizationId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `week` field. */
  readonly week?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `year` field. */
  readonly year?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `SalesVolThisWeek` object types. All fields are combined with a logical ‘and.’ */
export type SalesVolThisWeekFilter = {
  /** Filter by the object’s `amount` field. */
  readonly amount?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly SalesVolThisWeekFilter[]>;
  /** Filter by the object’s `delta` field. */
  readonly delta?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `merchantId` field. */
  readonly merchantId?: Maybe<UuidFilter>;
  /** Filter by the object’s `month` field. */
  readonly month?: Maybe<FloatFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<SalesVolThisWeekFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly SalesVolThisWeekFilter[]>;
  /** Filter by the object’s `organizationId` field. */
  readonly organizationId?: Maybe<UuidFilter>;
  /** Filter by the object’s `week` field. */
  readonly week?: Maybe<FloatFilter>;
  /** Filter by the object’s `year` field. */
  readonly year?: Maybe<FloatFilter>;
};

/** A connection to a list of `SalesVolThisWeek` values. */
export type SalesVolThisWeeksConnection = {
  /** A list of edges which contains the `SalesVolThisWeek` and cursor to aid in pagination. */
  readonly edges: readonly SalesVolThisWeeksEdge[];
  /** A list of `SalesVolThisWeek` objects. */
  readonly nodes: readonly Maybe<SalesVolThisWeek>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `SalesVolThisWeek` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `SalesVolThisWeek` edge in the connection. */
export type SalesVolThisWeeksEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `SalesVolThisWeek` at the end of the edge. */
  readonly node?: Maybe<SalesVolThisWeek>;
};

/** Methods to use when ordering `SalesVolThisWeek`. */
export enum SalesVolThisWeeksOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  DeltaAsc = 'DELTA_ASC',
  DeltaDesc = 'DELTA_DESC',
  MerchantIdAsc = 'MERCHANT_ID_ASC',
  MerchantIdDesc = 'MERCHANT_ID_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  WeekAsc = 'WEEK_ASC',
  WeekDesc = 'WEEK_DESC',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
}

export type SalesVolumeMerchantsToday = {
  readonly amount?: Maybe<Scalars['BigFloat']>;
  readonly hour?: Maybe<Scalars['Datetime']>;
  readonly organizationId?: Maybe<Scalars['UUID']>;
};

/** A condition to be used against `SalesVolumeMerchantsToday` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SalesVolumeMerchantsTodayCondition = {
  /** Checks for equality with the object’s `amount` field. */
  readonly amount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `hour` field. */
  readonly hour?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `organizationId` field. */
  readonly organizationId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `SalesVolumeMerchantsToday` object types. All fields are combined with a logical ‘and.’ */
export type SalesVolumeMerchantsTodayFilter = {
  /** Filter by the object’s `amount` field. */
  readonly amount?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly SalesVolumeMerchantsTodayFilter[]>;
  /** Filter by the object’s `hour` field. */
  readonly hour?: Maybe<DatetimeFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<SalesVolumeMerchantsTodayFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly SalesVolumeMerchantsTodayFilter[]>;
  /** Filter by the object’s `organizationId` field. */
  readonly organizationId?: Maybe<UuidFilter>;
};

/** A connection to a list of `SalesVolumeMerchantsToday` values. */
export type SalesVolumeMerchantsTodaysConnection = {
  /** A list of edges which contains the `SalesVolumeMerchantsToday` and cursor to aid in pagination. */
  readonly edges: readonly SalesVolumeMerchantsTodaysEdge[];
  /** A list of `SalesVolumeMerchantsToday` objects. */
  readonly nodes: readonly Maybe<SalesVolumeMerchantsToday>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `SalesVolumeMerchantsToday` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `SalesVolumeMerchantsToday` edge in the connection. */
export type SalesVolumeMerchantsTodaysEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `SalesVolumeMerchantsToday` at the end of the edge. */
  readonly node?: Maybe<SalesVolumeMerchantsToday>;
};

/** Methods to use when ordering `SalesVolumeMerchantsToday`. */
export enum SalesVolumeMerchantsTodaysOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  HourAsc = 'HOUR_ASC',
  HourDesc = 'HOUR_DESC',
  Natural = 'NATURAL',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
}

export type SalesVolumesDailyByOrganization = {
  readonly organizationId?: Maybe<Scalars['UUID']>;
  readonly salesVolumeToday?: Maybe<Scalars['JSON']>;
};

/** A condition to be used against `SalesVolumesDailyByOrganization` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SalesVolumesDailyByOrganizationCondition = {
  /** Checks for equality with the object’s `organizationId` field. */
  readonly organizationId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `salesVolumeToday` field. */
  readonly salesVolumeToday?: Maybe<Scalars['JSON']>;
};

/** A filter to be used against `SalesVolumesDailyByOrganization` object types. All fields are combined with a logical ‘and.’ */
export type SalesVolumesDailyByOrganizationFilter = {
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly SalesVolumesDailyByOrganizationFilter[]>;
  /** Negates the expression. */
  readonly not?: Maybe<SalesVolumesDailyByOrganizationFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly SalesVolumesDailyByOrganizationFilter[]>;
  /** Filter by the object’s `organizationId` field. */
  readonly organizationId?: Maybe<UuidFilter>;
  /** Filter by the object’s `salesVolumeToday` field. */
  readonly salesVolumeToday?: Maybe<JsonFilter>;
};

/** A connection to a list of `SalesVolumesDailyByOrganization` values. */
export type SalesVolumesDailyByOrganizationsConnection = {
  /** A list of edges which contains the `SalesVolumesDailyByOrganization` and cursor to aid in pagination. */
  readonly edges: readonly SalesVolumesDailyByOrganizationsEdge[];
  /** A list of `SalesVolumesDailyByOrganization` objects. */
  readonly nodes: readonly Maybe<SalesVolumesDailyByOrganization>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `SalesVolumesDailyByOrganization` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `SalesVolumesDailyByOrganization` edge in the connection. */
export type SalesVolumesDailyByOrganizationsEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `SalesVolumesDailyByOrganization` at the end of the edge. */
  readonly node?: Maybe<SalesVolumesDailyByOrganization>;
};

/** Methods to use when ordering `SalesVolumesDailyByOrganization`. */
export enum SalesVolumesDailyByOrganizationsOrderBy {
  Natural = 'NATURAL',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  SalesVolumeTodayAsc = 'SALES_VOLUME_TODAY_ASC',
  SalesVolumeTodayDesc = 'SALES_VOLUME_TODAY_DESC',
}

/** A connection to a list of `SalesVolumesMonthly` values. */
export type SalesVolumesMonthliesConnection = {
  /** A list of edges which contains the `SalesVolumesMonthly` and cursor to aid in pagination. */
  readonly edges: readonly SalesVolumesMonthliesEdge[];
  /** A list of `SalesVolumesMonthly` objects. */
  readonly nodes: readonly Maybe<SalesVolumesMonthly>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `SalesVolumesMonthly` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `SalesVolumesMonthly` edge in the connection. */
export type SalesVolumesMonthliesEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `SalesVolumesMonthly` at the end of the edge. */
  readonly node?: Maybe<SalesVolumesMonthly>;
};

/** Methods to use when ordering `SalesVolumesMonthly`. */
export enum SalesVolumesMonthliesOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  MerchantIdAsc = 'MERCHANT_ID_ASC',
  MerchantIdDesc = 'MERCHANT_ID_DESC',
  MonthAsc = 'MONTH_ASC',
  MonthDesc = 'MONTH_DESC',
  MonthNameAsc = 'MONTH_NAME_ASC',
  MonthNameDesc = 'MONTH_NAME_DESC',
  Natural = 'NATURAL',
}

export type SalesVolumesMonthly = {
  readonly amount?: Maybe<Scalars['BigFloat']>;
  readonly merchantId?: Maybe<Scalars['UUID']>;
  readonly month?: Maybe<Scalars['Int']>;
  readonly monthName?: Maybe<Scalars['String']>;
};

/** A condition to be used against `SalesVolumesMonthly` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SalesVolumesMonthlyCondition = {
  /** Checks for equality with the object’s `amount` field. */
  readonly amount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `merchantId` field. */
  readonly merchantId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `month` field. */
  readonly month?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `monthName` field. */
  readonly monthName?: Maybe<Scalars['String']>;
};

/** A filter to be used against `SalesVolumesMonthly` object types. All fields are combined with a logical ‘and.’ */
export type SalesVolumesMonthlyFilter = {
  /** Filter by the object’s `amount` field. */
  readonly amount?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly SalesVolumesMonthlyFilter[]>;
  /** Filter by the object’s `merchantId` field. */
  readonly merchantId?: Maybe<UuidFilter>;
  /** Filter by the object’s `month` field. */
  readonly month?: Maybe<IntFilter>;
  /** Filter by the object’s `monthName` field. */
  readonly monthName?: Maybe<StringFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<SalesVolumesMonthlyFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly SalesVolumesMonthlyFilter[]>;
};

export type SalesVolumesWeeklyByOrganization = {
  readonly organizationId?: Maybe<Scalars['UUID']>;
  readonly salesVolThisWeek?: Maybe<Scalars['JSON']>;
};

/** A condition to be used against `SalesVolumesWeeklyByOrganization` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SalesVolumesWeeklyByOrganizationCondition = {
  /** Checks for equality with the object’s `organizationId` field. */
  readonly organizationId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `salesVolThisWeek` field. */
  readonly salesVolThisWeek?: Maybe<Scalars['JSON']>;
};

/** A filter to be used against `SalesVolumesWeeklyByOrganization` object types. All fields are combined with a logical ‘and.’ */
export type SalesVolumesWeeklyByOrganizationFilter = {
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly SalesVolumesWeeklyByOrganizationFilter[]>;
  /** Negates the expression. */
  readonly not?: Maybe<SalesVolumesWeeklyByOrganizationFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly SalesVolumesWeeklyByOrganizationFilter[]>;
  /** Filter by the object’s `organizationId` field. */
  readonly organizationId?: Maybe<UuidFilter>;
  /** Filter by the object’s `salesVolThisWeek` field. */
  readonly salesVolThisWeek?: Maybe<JsonFilter>;
};

/** A connection to a list of `SalesVolumesWeeklyByOrganization` values. */
export type SalesVolumesWeeklyByOrganizationsConnection = {
  /** A list of edges which contains the `SalesVolumesWeeklyByOrganization` and cursor to aid in pagination. */
  readonly edges: readonly SalesVolumesWeeklyByOrganizationsEdge[];
  /** A list of `SalesVolumesWeeklyByOrganization` objects. */
  readonly nodes: readonly Maybe<SalesVolumesWeeklyByOrganization>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `SalesVolumesWeeklyByOrganization` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `SalesVolumesWeeklyByOrganization` edge in the connection. */
export type SalesVolumesWeeklyByOrganizationsEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `SalesVolumesWeeklyByOrganization` at the end of the edge. */
  readonly node?: Maybe<SalesVolumesWeeklyByOrganization>;
};

/** Methods to use when ordering `SalesVolumesWeeklyByOrganization`. */
export enum SalesVolumesWeeklyByOrganizationsOrderBy {
  Natural = 'NATURAL',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  SalesVolThisWeekAsc = 'SALES_VOL_THIS_WEEK_ASC',
  SalesVolThisWeekDesc = 'SALES_VOL_THIS_WEEK_DESC',
}

export type StoreTimeSlot = {
  readonly available?: Maybe<Scalars['BigInt']>;
  readonly dateTime?: Maybe<Scalars['Datetime']>;
  readonly limit?: Maybe<Scalars['Int']>;
  readonly storeId?: Maybe<Scalars['UUID']>;
  readonly time?: Maybe<Scalars['Time']>;
  readonly timeSlotId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `StoreTimeSlot` object types. All fields are combined with a logical ‘and.’ */
export type StoreTimeSlotFilter = {
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly StoreTimeSlotFilter[]>;
  /** Filter by the object’s `available` field. */
  readonly available?: Maybe<BigIntFilter>;
  /** Filter by the object’s `dateTime` field. */
  readonly dateTime?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `limit` field. */
  readonly limit?: Maybe<IntFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<StoreTimeSlotFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly StoreTimeSlotFilter[]>;
  /** Filter by the object’s `storeId` field. */
  readonly storeId?: Maybe<UuidFilter>;
  /** Filter by the object’s `time` field. */
  readonly time?: Maybe<TimeFilter>;
  /** Filter by the object’s `timeSlotId` field. */
  readonly timeSlotId?: Maybe<UuidFilter>;
};

/** A connection to a list of `StoreTimeSlot` values. */
export type StoreTimeSlotsConnection = {
  /** A list of edges which contains the `StoreTimeSlot` and cursor to aid in pagination. */
  readonly edges: readonly StoreTimeSlotsEdge[];
  /** A list of `StoreTimeSlot` objects. */
  readonly nodes: readonly Maybe<StoreTimeSlot>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `StoreTimeSlot` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `StoreTimeSlot` edge in the connection. */
export type StoreTimeSlotsEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `StoreTimeSlot` at the end of the edge. */
  readonly node?: Maybe<StoreTimeSlot>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  readonly distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  readonly endsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  readonly endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  readonly equalToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  readonly greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  readonly greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  readonly in?: Maybe<readonly Scalars['String'][]>;
  /** Included in the specified list (case-insensitive). */
  readonly inInsensitive?: Maybe<readonly Scalars['String'][]>;
  /** Contains the specified string (case-sensitive). */
  readonly includes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  readonly includesInsensitive?: Maybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  readonly lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  readonly lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  readonly like?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  readonly likeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  readonly notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  readonly notEndsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  readonly notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  readonly notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<readonly Scalars['String'][]>;
  /** Not included in the specified list (case-insensitive). */
  readonly notInInsensitive?: Maybe<readonly Scalars['String'][]>;
  /** Does not contain the specified string (case-sensitive). */
  readonly notIncludes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  readonly notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  readonly notLike?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  readonly notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  readonly notStartsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  readonly notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  readonly startsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  readonly startsWithInsensitive?: Maybe<Scalars['String']>;
};

/** A filter to be used against Time fields. All fields are combined with a logical ‘and.’ */
export type TimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['Time']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['Time']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['Time']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['Time']>;
  /** Included in the specified list. */
  readonly in?: Maybe<readonly Scalars['Time'][]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['Time']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['Time']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['Time']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['Time']>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<readonly Scalars['Time'][]>;
};

export type Transaction = {
  readonly cardAuthorizationRequestId?: Maybe<Scalars['String']>;
  readonly cardCaptureRequestId?: Maybe<Scalars['String']>;
  readonly data?: Maybe<Scalars['JSON']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly merchantEmployee?: Maybe<Scalars['JSON']>;
  readonly merchantEmployeeId?: Maybe<Scalars['UUID']>;
  readonly merchantId?: Maybe<Scalars['UUID']>;
  readonly merchantStoreId?: Maybe<Scalars['UUID']>;
  readonly merchantStoreName?: Maybe<Scalars['String']>;
  readonly netTotalAmount?: Maybe<Scalars['BigFloat']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly paymentProvider?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly restaurantOrderId?: Maybe<Scalars['UUID']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly totalAmount?: Maybe<Scalars['BigFloat']>;
  readonly transactionId?: Maybe<Scalars['UUID']>;
  readonly transactionStatus?: Maybe<Scalars['String']>;
  readonly userCardId?: Maybe<Scalars['UUID']>;
  readonly userId?: Maybe<Scalars['UUID']>;
};

export type TransactionDataThisWeek = {
  readonly amount?: Maybe<Scalars['Float']>;
  readonly avgAmount?: Maybe<Scalars['BigFloat']>;
  readonly bestSellingMerchant?: Maybe<Scalars['String']>;
  readonly delta?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `TransactionDataThisWeek` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TransactionDataThisWeekCondition = {
  /** Checks for equality with the object’s `amount` field. */
  readonly amount?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `avgAmount` field. */
  readonly avgAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `bestSellingMerchant` field. */
  readonly bestSellingMerchant?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `delta` field. */
  readonly delta?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `TransactionDataThisWeek` object types. All fields are combined with a logical ‘and.’ */
export type TransactionDataThisWeekFilter = {
  /** Filter by the object’s `amount` field. */
  readonly amount?: Maybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly TransactionDataThisWeekFilter[]>;
  /** Filter by the object’s `avgAmount` field. */
  readonly avgAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `bestSellingMerchant` field. */
  readonly bestSellingMerchant?: Maybe<StringFilter>;
  /** Filter by the object’s `delta` field. */
  readonly delta?: Maybe<BigFloatFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<TransactionDataThisWeekFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly TransactionDataThisWeekFilter[]>;
};

/** A connection to a list of `TransactionDataThisWeek` values. */
export type TransactionDataThisWeeksConnection = {
  /** A list of edges which contains the `TransactionDataThisWeek` and cursor to aid in pagination. */
  readonly edges: readonly TransactionDataThisWeeksEdge[];
  /** A list of `TransactionDataThisWeek` objects. */
  readonly nodes: readonly Maybe<TransactionDataThisWeek>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `TransactionDataThisWeek` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `TransactionDataThisWeek` edge in the connection. */
export type TransactionDataThisWeeksEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionDataThisWeek` at the end of the edge. */
  readonly node?: Maybe<TransactionDataThisWeek>;
};

/** Methods to use when ordering `TransactionDataThisWeek`. */
export enum TransactionDataThisWeeksOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  AvgAmountAsc = 'AVG_AMOUNT_ASC',
  AvgAmountDesc = 'AVG_AMOUNT_DESC',
  BestSellingMerchantAsc = 'BEST_SELLING_MERCHANT_ASC',
  BestSellingMerchantDesc = 'BEST_SELLING_MERCHANT_DESC',
  DeltaAsc = 'DELTA_ASC',
  DeltaDesc = 'DELTA_DESC',
  Natural = 'NATURAL',
}

/** A filter to be used against `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type TransactionFilter = {
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly TransactionFilter[]>;
  /** Filter by the object’s `cardAuthorizationRequestId` field. */
  readonly cardAuthorizationRequestId?: Maybe<StringFilter>;
  /** Filter by the object’s `cardCaptureRequestId` field. */
  readonly cardCaptureRequestId?: Maybe<StringFilter>;
  /** Filter by the object’s `data` field. */
  readonly data?: Maybe<JsonFilter>;
  /** Filter by the object’s `dateCreated` field. */
  readonly dateCreated?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  readonly email?: Maybe<StringFilter>;
  /** Filter by the object’s `merchantEmployee` field. */
  readonly merchantEmployee?: Maybe<JsonFilter>;
  /** Filter by the object’s `merchantEmployeeId` field. */
  readonly merchantEmployeeId?: Maybe<UuidFilter>;
  /** Filter by the object’s `merchantId` field. */
  readonly merchantId?: Maybe<UuidFilter>;
  /** Filter by the object’s `merchantStoreId` field. */
  readonly merchantStoreId?: Maybe<UuidFilter>;
  /** Filter by the object’s `merchantStoreName` field. */
  readonly merchantStoreName?: Maybe<StringFilter>;
  /** Filter by the object’s `netTotalAmount` field. */
  readonly netTotalAmount?: Maybe<BigFloatFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<TransactionFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly TransactionFilter[]>;
  /** Filter by the object’s `order` field. */
  readonly order?: Maybe<JsonFilter>;
  /** Filter by the object’s `paymentProvider` field. */
  readonly paymentProvider?: Maybe<StringFilter>;
  /** Filter by the object’s `requestId` field. */
  readonly requestId?: Maybe<StringFilter>;
  /** Filter by the object’s `restaurantOrderId` field. */
  readonly restaurantOrderId?: Maybe<UuidFilter>;
  /** Filter by the object’s `status` field. */
  readonly status?: Maybe<StringFilter>;
  /** Filter by the object’s `totalAmount` field. */
  readonly totalAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `transactionId` field. */
  readonly transactionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `transactionStatus` field. */
  readonly transactionStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `userCardId` field. */
  readonly userCardId?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  readonly userId?: Maybe<UuidFilter>;
};

/** A connection to a list of `Transaction` values. */
export type TransactionsConnection = {
  /** A list of edges which contains the `Transaction` and cursor to aid in pagination. */
  readonly edges: readonly TransactionsEdge[];
  /** A list of `Transaction` objects. */
  readonly nodes: readonly Maybe<Transaction>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Transaction` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Transaction` edge in the connection. */
export type TransactionsEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Transaction` at the end of the edge. */
  readonly node?: Maybe<Transaction>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  readonly in?: Maybe<readonly Scalars['UUID'][]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<readonly Scalars['UUID'][]>;
};

/** A filter to be used against UUID List fields. All fields are combined with a logical ‘and.’ */
export type UuidListFilter = {
  /** Any array item is equal to the specified value. */
  readonly anyEqualTo?: Maybe<Scalars['UUID']>;
  /** Any array item is greater than the specified value. */
  readonly anyGreaterThan?: Maybe<Scalars['UUID']>;
  /** Any array item is greater than or equal to the specified value. */
  readonly anyGreaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Any array item is less than the specified value. */
  readonly anyLessThan?: Maybe<Scalars['UUID']>;
  /** Any array item is less than or equal to the specified value. */
  readonly anyLessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Any array item is not equal to the specified value. */
  readonly anyNotEqualTo?: Maybe<Scalars['UUID']>;
  /** Contained by the specified list of values. */
  readonly containedBy?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  /** Contains the specified list of values. */
  readonly contains?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
  /** Overlaps the specified list of values. */
  readonly overlaps?: Maybe<readonly Maybe<Scalars['UUID']>[]>;
};

/** All input for the `updatePassword` mutation. */
export type UpdatePasswordInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly req?: Maybe<ChangePasswordRequestModelInput>;
};

/** The output of our `updatePassword` mutation. */
export type UpdatePasswordPayload = {
  readonly boolean?: Maybe<Scalars['Boolean']>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly req?: Maybe<UserInput>;
};

/** The output of our `updateUser` mutation. */
export type UpdateUserPayload = {
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  readonly userLoginDatum?: Maybe<UserLoginDatum>;
};

/** All input for the `updateUserPreferences` mutation. */
export type UpdateUserPreferencesInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly req?: Maybe<UserLoginDatumInput>;
};

/** The output of our `updateUserPreferences` mutation. */
export type UpdateUserPreferencesPayload = {
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  readonly userLoginDatum?: Maybe<UserLoginDatum>;
};

export type UserCard = {
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly expirationMonth?: Maybe<Scalars['String']>;
  readonly expirationYear?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly number?: Maybe<Scalars['String']>;
  readonly paymentProvider?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly userCardId?: Maybe<Scalars['UUID']>;
  readonly userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `UserCard` object types. All fields are combined with a logical ‘and.’ */
export type UserCardFilter = {
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<readonly UserCardFilter[]>;
  /** Filter by the object’s `city` field. */
  readonly city?: Maybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  readonly country?: Maybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  readonly email?: Maybe<StringFilter>;
  /** Filter by the object’s `expirationMonth` field. */
  readonly expirationMonth?: Maybe<StringFilter>;
  /** Filter by the object’s `expirationYear` field. */
  readonly expirationYear?: Maybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  readonly firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `label` field. */
  readonly label?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  readonly lastName?: Maybe<StringFilter>;
  /** Negates the expression. */
  readonly not?: Maybe<UserCardFilter>;
  /** Filter by the object’s `number` field. */
  readonly number?: Maybe<StringFilter>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<readonly UserCardFilter[]>;
  /** Filter by the object’s `paymentProvider` field. */
  readonly paymentProvider?: Maybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  readonly status?: Maybe<StringFilter>;
  /** Filter by the object’s `street` field. */
  readonly street?: Maybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  readonly type?: Maybe<StringFilter>;
  /** Filter by the object’s `userCardId` field. */
  readonly userCardId?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  readonly userId?: Maybe<UuidFilter>;
};

/** A connection to a list of `UserCard` values. */
export type UserCardsConnection = {
  /** A list of edges which contains the `UserCard` and cursor to aid in pagination. */
  readonly edges: readonly UserCardsEdge[];
  /** A list of `UserCard` objects. */
  readonly nodes: readonly Maybe<UserCard>[];
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `UserCard` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `UserCard` edge in the connection. */
export type UserCardsEdge = {
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserCard` at the end of the edge. */
  readonly node?: Maybe<UserCard>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  readonly addressLineOne?: Maybe<Scalars['String']>;
  readonly addressLineTwo?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly dateOfBirth?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly emailActivationCode?: Maybe<Scalars['String']>;
  readonly emailConfirmed?: Maybe<Scalars['Boolean']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly phoneActivationCode?: Maybe<Scalars['String']>;
  readonly phoneConfirmed?: Maybe<Scalars['Boolean']>;
  readonly postCode?: Maybe<Scalars['String']>;
  readonly typeaheadLocation?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['UUID']>;
};

export type UserLoginDatum = {
  readonly addressLineOne?: Maybe<Scalars['String']>;
  readonly addressLineTwo?: Maybe<Scalars['String']>;
  readonly analytics?: Maybe<Scalars['Boolean']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly dateOfBirth?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly emailConfirmed?: Maybe<Scalars['Boolean']>;
  readonly faceIdToPay?: Maybe<Scalars['Boolean']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly fullName?: Maybe<Scalars['String']>;
  readonly hasPrideIcons?: Maybe<Scalars['Boolean']>;
  readonly isAliPay?: Maybe<Scalars['Boolean']>;
  readonly isClickCollect?: Maybe<Scalars['Boolean']>;
  readonly isTransactionBatching?: Maybe<Scalars['Boolean']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly loginWithFaceId?: Maybe<Scalars['Boolean']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly postCode?: Maybe<Scalars['String']>;
  readonly roles?: Maybe<Scalars['JSON']>;
  readonly termsAndConditions?: Maybe<Scalars['Boolean']>;
  readonly typeaheadLocation?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['UUID']>;
};

/** An input for mutations affecting `UserLoginDatum` */
export type UserLoginDatumInput = {
  readonly addressLineOne?: Maybe<Scalars['String']>;
  readonly addressLineTwo?: Maybe<Scalars['String']>;
  readonly analytics?: Maybe<Scalars['Boolean']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly dateOfBirth?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly emailConfirmed?: Maybe<Scalars['Boolean']>;
  readonly faceIdToPay?: Maybe<Scalars['Boolean']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly fullName?: Maybe<Scalars['String']>;
  readonly hasPrideIcons?: Maybe<Scalars['Boolean']>;
  readonly isAliPay?: Maybe<Scalars['Boolean']>;
  readonly isClickCollect?: Maybe<Scalars['Boolean']>;
  readonly isTransactionBatching?: Maybe<Scalars['Boolean']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly loginWithFaceId?: Maybe<Scalars['Boolean']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly postCode?: Maybe<Scalars['String']>;
  readonly roles?: Maybe<Scalars['JSON']>;
  readonly termsAndConditions?: Maybe<Scalars['Boolean']>;
  readonly typeaheadLocation?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['UUID']>;
};

export type AllConfigurableMerchantItemsQueryVariables = Exact<{ [key: string]: never }>;

export type AllConfigurableMerchantItemsQuery = {
  readonly allConfigurableMerchantItems: {
    readonly nodes: readonly (
      | {
          readonly statusId?: any | null | undefined;
          readonly barcode?: string | null | undefined;
          readonly count?: any | null | undefined;
          readonly description?: string | null | undefined;
          readonly isCustom?: boolean | null | undefined;
          readonly isEatIn?: boolean | null | undefined;
          readonly isGlutenFree?: boolean | null | undefined;
          readonly isOnOffer?: boolean | null | undefined;
          readonly isTakeout?: boolean | null | undefined;
          readonly isVegan?: boolean | null | undefined;
          readonly isVegetarian?: boolean | null | undefined;
          readonly itemId?: string | null | undefined;
          readonly menuCategoryId?: any | null | undefined;
          readonly menuCategoryOrderIndex?: number | null | undefined;
          readonly merchantId?: any | null | undefined;
          readonly merchantItemId?: any | null | undefined;
          readonly merchantStoreIds?: readonly (any | null | undefined)[] | null | undefined;
          readonly name?: string | null | undefined;
          readonly newPrice?: any | null | undefined;
          readonly offerTypeId?: any | null | undefined;
          readonly price?: any | null | undefined;
          readonly weight?: string | null | undefined;
          readonly isClickCollect?: boolean | null | undefined;
          readonly isEigtheenPlus?: boolean | null | undefined;
          readonly addonItems?: any | null | undefined;
          readonly image?:
            | { readonly fileName?: string | null | undefined; readonly mimeType?: string | null | undefined }
            | null
            | undefined;
        }
      | null
      | undefined
    )[];
  };
};

export type GetStoreAvailableSlotsQueryVariables = Exact<{
  storeId?: Maybe<Scalars['UUID']>;
}>;

export type GetStoreAvailableSlotsQuery = {
  readonly timeSlotsByStoreId: {
    readonly nodes: readonly (
      | {
          readonly available?: any | null | undefined;
          readonly storeId?: any | null | undefined;
          readonly time?: any | null | undefined;
          readonly timeSlotId?: any | null | undefined;
          readonly dateTime?: any | null | undefined;
        }
      | null
      | undefined
    )[];
  };
};

export type GetStoreByIdQueryVariables = Exact<{
  filter?: Maybe<MerchantStoreFilter>;
}>;

export type GetStoreByIdQuery = {
  readonly allMerchantStores?:
    | {
        readonly nodes: readonly (
          | {
              readonly merchantId?: any | null | undefined;
              readonly merchantStoreId?: any | null | undefined;
              readonly name?: string | null | undefined;
              readonly workingHours?: any | null | undefined;
              readonly isHotFrozen?: boolean | null | undefined;
              readonly isClickCollect?: boolean | null | undefined;
              readonly serviceCharge?: boolean | null | undefined;
              readonly serviceChargeAmount?: any | null | undefined;
              readonly enableBagCharge?: boolean | null | undefined;
              readonly bagChargePrice?: any | null | undefined;
              readonly bagChargeNetPrice?: any | null | undefined;
              readonly googlePayPublicKey?: string | null | undefined;
              readonly applePayPublicKey?: string | null | undefined;
              readonly applePayProviderId?: string | null | undefined;
              readonly googlePayProviderId?: string | null | undefined;
              readonly supportedPaymentMethods?: any | null | undefined;
              readonly nextOpen?: any | null | undefined;
              readonly waitTimeMinutes?: number | null | undefined;
            }
          | null
          | undefined
        )[];
      }
    | null
    | undefined;
};

export type GetStoreCategoriesByStoreIdQueryVariables = Exact<{
  storeId?: Maybe<Scalars['UUID']>;
}>;

export type GetStoreCategoriesByStoreIdQuery = {
  readonly restaurantMenuCategoriesByMerchantStoreId: {
    readonly nodes: readonly (
      | {
          readonly statusId?: any | null | undefined;
          readonly merchantId?: any | null | undefined;
          readonly name?: string | null | undefined;
          readonly restaurantMenuCategoryId?: any | null | undefined;
        }
      | null
      | undefined
    )[];
  };
};

export type GetStoreItemsByStoreIdQueryVariables = Exact<{
  filter?: Maybe<MerchantItemFilter>;
}>;

export type GetStoreItemsByStoreIdQuery = {
  readonly allMerchantItems?:
    | {
        readonly nodes: readonly (
          | {
              readonly barcode?: string | null | undefined;
              readonly count?: any | null | undefined;
              readonly description?: string | null | undefined;
              readonly isCustom?: boolean | null | undefined;
              readonly isEatIn?: boolean | null | undefined;
              readonly isGlutenFree?: boolean | null | undefined;
              readonly isOnOffer?: boolean | null | undefined;
              readonly isTakeout?: boolean | null | undefined;
              readonly isVegan?: boolean | null | undefined;
              readonly isVegetarian?: boolean | null | undefined;
              readonly itemId?: string | null | undefined;
              readonly statusId?: any | null | undefined;
              readonly menuCategoryId?: any | null | undefined;
              readonly menuCategoryOrderIndex?: number | null | undefined;
              readonly merchantId?: any | null | undefined;
              readonly merchantItemId?: any | null | undefined;
              readonly merchantStoreIds?: readonly (any | null | undefined)[] | null | undefined;
              readonly name?: string | null | undefined;
              readonly newPrice?: any | null | undefined;
              readonly offerTypeId?: any | null | undefined;
              readonly price?: any | null | undefined;
              readonly weight?: string | null | undefined;
              readonly isClickCollect?: boolean | null | undefined;
              readonly addonItems?: any | null | undefined;
              readonly image?:
                | { readonly fileName?: string | null | undefined; readonly mimeType?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined
        )[];
      }
    | null
    | undefined;
};

export type GetItemsByOrderIdQueryMutationVariables = Exact<{
  restaurantOrderId?: Maybe<Scalars['UUID']>;
}>;

export type GetItemsByOrderIdQueryMutation = {
  readonly orderItemsWithRefunds?:
    | {
        readonly orderItemsWithRefund?:
          | {
              readonly restaurantOrderId?: any | null | undefined;
              readonly refundItems?:
                | readonly (
                    | {
                        readonly barcode?: string | null | undefined;
                        readonly collectStatus?: string | null | undefined;
                        readonly count?: any | null | undefined;
                        readonly isCustom?: boolean | null | undefined;
                        readonly description?: string | null | undefined;
                        readonly isEatIn?: boolean | null | undefined;
                        readonly isGlutenFree?: boolean | null | undefined;
                        readonly isVegan?: boolean | null | undefined;
                        readonly isTakeout?: boolean | null | undefined;
                        readonly isOnOffer?: boolean | null | undefined;
                        readonly isVegetarian?: boolean | null | undefined;
                        readonly itemId?: string | null | undefined;
                        readonly merchantId?: any | null | undefined;
                        readonly merchantItemId?: any | null | undefined;
                        readonly name?: string | null | undefined;
                        readonly newPrice?: any | null | undefined;
                        readonly note?: string | null | undefined;
                        readonly offerTypeId?: any | null | undefined;
                        readonly price?: any | null | undefined;
                        readonly quantity?: number | null | undefined;
                        readonly restaurantOrderItemId?: any | null | undefined;
                        readonly statusId?: any | null | undefined;
                        readonly weight?: string | null | undefined;
                        readonly parentRestaurantOrderItemId?: any | null | undefined;
                        readonly addonItems?:
                          | readonly (
                              | { readonly merchantItemId?: any | null | undefined; readonly quantity?: number | null | undefined }
                              | null
                              | undefined
                            )[]
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  )[]
                | null
                | undefined;
              readonly transactionItems?:
                | readonly (
                    | {
                        readonly barcode?: string | null | undefined;
                        readonly collectStatus?: string | null | undefined;
                        readonly count?: any | null | undefined;
                        readonly isCustom?: boolean | null | undefined;
                        readonly description?: string | null | undefined;
                        readonly isEatIn?: boolean | null | undefined;
                        readonly isGlutenFree?: boolean | null | undefined;
                        readonly isOnOffer?: boolean | null | undefined;
                        readonly isTakeout?: boolean | null | undefined;
                        readonly isVegan?: boolean | null | undefined;
                        readonly isVegetarian?: boolean | null | undefined;
                        readonly merchantId?: any | null | undefined;
                        readonly itemId?: string | null | undefined;
                        readonly name?: string | null | undefined;
                        readonly merchantItemId?: any | null | undefined;
                        readonly newPrice?: any | null | undefined;
                        readonly note?: string | null | undefined;
                        readonly offerTypeId?: any | null | undefined;
                        readonly price?: any | null | undefined;
                        readonly quantity?: number | null | undefined;
                        readonly restaurantOrderItemId?: any | null | undefined;
                        readonly statusId?: any | null | undefined;
                        readonly weight?: string | null | undefined;
                      }
                    | null
                    | undefined
                  )[]
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetOrderByIdQueryVariables = Exact<{
  restaurantOrderId?: Maybe<Scalars['UUID']>;
}>;

export type GetOrderByIdQuery = {
  readonly ordersWithMerchantId: {
    readonly nodes: readonly (
      | {
          readonly isRead?: boolean | null | undefined;
          readonly itemsCount?: number | null | undefined;
          readonly merchantId?: any | null | undefined;
          readonly merchantStoreId?: any | null | undefined;
          readonly merchantStoreName?: string | null | undefined;
          readonly orderNumber?: string | null | undefined;
          readonly restaurantOrderId?: any | null | undefined;
          readonly status?: string | null | undefined;
          readonly tableNumber?: number | null | undefined;
          readonly timeToComplete?: number | null | undefined;
          readonly totalAmount?: any | null | undefined;
          readonly type?: string | null | undefined;
          readonly clickCollectAlternative?: string | null | undefined;
          readonly timeSlotId?: string | null | undefined;
          readonly timeSlotDateTime?: any | null | undefined;
          readonly dateCreated?: any | null | undefined;
          readonly userCardId?: any | null | undefined;
          readonly bagCharge?: boolean | null | undefined;
          readonly serviceChargeAmount?: any | null | undefined;
          readonly manualDeduction?: any | null | undefined;
          readonly isFullyRefunded?: boolean | null | undefined;
          readonly transactionId?: any | null | undefined;
          readonly transactionTotalAmount?: any | null | undefined;
          readonly purged?: boolean | null | undefined;
          readonly bagChargePrice?: any | null | undefined;
          readonly bagChargeNetPrice?: any | null | undefined;
          readonly notes?: string | null | undefined;
          readonly items?:
            | readonly (
                | {
                    readonly count?: any | null | undefined;
                    readonly description?: string | null | undefined;
                    readonly isCustom?: boolean | null | undefined;
                    readonly isEatIn?: boolean | null | undefined;
                    readonly isGlutenFree?: boolean | null | undefined;
                    readonly isOnOffer?: boolean | null | undefined;
                    readonly isTakeout?: boolean | null | undefined;
                    readonly isVegan?: boolean | null | undefined;
                    readonly isVegetarian?: boolean | null | undefined;
                    readonly itemId?: string | null | undefined;
                    readonly merchantId?: any | null | undefined;
                    readonly merchantItemId?: any | null | undefined;
                    readonly note?: string | null | undefined;
                    readonly name?: string | null | undefined;
                    readonly price?: any | null | undefined;
                    readonly quantity?: number | null | undefined;
                    readonly statusId?: any | null | undefined;
                    readonly weight?: string | null | undefined;
                    readonly offerTypeId?: any | null | undefined;
                    readonly newPrice?: any | null | undefined;
                    readonly barcode?: string | null | undefined;
                    readonly restaurantOrderItemId?: any | null | undefined;
                    readonly collectStatus?: string | null | undefined;
                  }
                | null
                | undefined
              )[]
            | null
            | undefined;
        }
      | null
      | undefined
    )[];
  };
};

export const AllConfigurableMerchantItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allConfigurableMerchantItems' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allConfigurableMerchantItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEatIn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isGlutenFree' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isOnOffer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isTakeout' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVegan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVegetarian' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'menuCategoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'menuCategoryOrderIndex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantItemId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantStoreIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offerTypeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isClickCollect' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEighteenPlus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'addonItems' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllConfigurableMerchantItemsQuery, AllConfigurableMerchantItemsQueryVariables>;
export const GetStoreAvailableSlotsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStoreAvailableSlots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeSlotsByStoreId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantStoreId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'available' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'time' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeSlotId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStoreAvailableSlotsQuery, GetStoreAvailableSlotsQueryVariables>;
export const GetStoreByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStoreById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantStoreFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allMerchantStores' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantStoreId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'workingHours' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isHotFrozen' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isClickCollect' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serviceCharge' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serviceChargeAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enableBagCharge' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bagChargePrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bagChargeNetPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'googlePayPublicKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applePayPublicKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applePayProviderId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'googlePayProviderId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'supportedPaymentMethods' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextOpen' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'waitTimeMinutes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStoreByIdQuery, GetStoreByIdQueryVariables>;
export const GetStoreCategoriesByStoreIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStoreCategoriesByStoreId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restaurantMenuCategoriesByMerchantStoreId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantStoreId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'restaurantMenuCategoryId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStoreCategoriesByStoreIdQuery, GetStoreCategoriesByStoreIdQueryVariables>;
export const GetStoreItemsByStoreIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStoreItemsByStoreId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantItemFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allMerchantItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEatIn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isGlutenFree' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isOnOffer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isTakeout' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVegan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVegetarian' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'menuCategoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'menuCategoryOrderIndex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantItemId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantStoreIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offerTypeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isClickCollect' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'addonItems' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStoreItemsByStoreIdQuery, GetStoreItemsByStoreIdQueryVariables>;
export const GetItemsByOrderIdQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetItemsByOrderIdQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'restaurantOrderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderItemsWithRefunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'restaurantOrderId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'restaurantOrderId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderItemsWithRefund' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'restaurantOrderId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refundItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'collectStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isEatIn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isGlutenFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVegan' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isTakeout' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isOnOffer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVegetarian' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'merchantId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'merchantItemId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'offerTypeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'restaurantOrderItemId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'parentRestaurantOrderItemId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addonItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'merchantItemId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transactionItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'collectStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isEatIn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isGlutenFree' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isOnOffer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isTakeout' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVegan' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVegetarian' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'merchantId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'merchantItemId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'offerTypeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'restaurantOrderItemId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetItemsByOrderIdQueryMutation, GetItemsByOrderIdQueryMutationVariables>;
