import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, exhaustMap, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { authHeaders, Environment } from '@libs/shared/types';
import { APP_ENVIRONMENT } from '@libs/shared/utilities';
import { Session } from '../../interfaces/session.interface';
import { currentSession } from '../../+state/selectors/session.selector';

@Injectable({ providedIn: 'any' })
export class SessionService {
  constructor(private store: Store, private http: HttpClient, @Inject(APP_ENVIRONMENT) private environment: Environment) {}

  currentSession(): Observable<Session> {
    return this.store.select(currentSession).pipe(
      take(1),
      exhaustMap((session) => {
        const now = Math.round(new Date().getTime() / 1000) + 35;
        if (session?.accessTokenExp <= now) {
          return this.http
            .get<Session>(`${this.environment.baseApiUrl}/mobile/refreshToken`, {
              headers: {
                'refresh-token': session.refreshToken,
                [authHeaders.signedHeaderKey]: 'true',
              },
            })
            .pipe(
              exhaustMap(() =>
                this.store.select(currentSession).pipe(
                  filter((session: Session) => {
                    const now = Math.round(new Date().getTime() / 1000) + 35;
                    return session?.accessTokenExp > now;
                  })
                )
              )
            );
        } else {
          return of(session);
        }
      })
    );
  }
}
