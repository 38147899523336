import { Injectable } from '@angular/core';
import { ActionSheetController, ActionSheetOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ActionSheetService {
  constructor(private actionSheetController: ActionSheetController) {}

  async present({ cssClass = 'kp-action-sheet', ...rest }: ActionSheetOptions): Promise<HTMLIonActionSheetElement> {
    const actionSheet = await this.actionSheetController.create({
      cssClass,
      ...rest,
    });
    await actionSheet.present();
    return actionSheet;
  }
}
