import { ApplePayPaymentRequest } from './apple-pay-request.interface';
import { ApplePaySession } from './apple-pay-session.interface';

export interface ApplePayJs {
  new (version: number, request: ApplePayPaymentRequest): ApplePaySession;
  canMakePayments: () => boolean;
  canMakePaymentsWithActiveCard: (merchantIdentifier: string) => Promise<boolean>;
  supportsVersion: (version: number) => boolean;
  openPaymentSetup(merchantIdentifier: string): Promise<boolean>;
}
