import { RoleObject } from './roles-object.interface';

export interface User {
  userId?: string;
  firstName?: string;
  email?: string;
  emailConfirmed?: boolean;
  lastName?: string;
  fullName?: string;
  dateOfBirth?: string;
  phone?: string;
  phoneVerified?: boolean;
  addressLineOne?: string;
  addressLineTwo?: string;
  typeaheadLocation?: string;
  city?: string;
  postCode?: string;
  isClickCollect?: boolean;
  isAliPay?: boolean;
  analytics?: boolean;
  intercomKey?: string;
  hasPrideIcons?: boolean;
  roles?: RoleObject[];
}
