import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ItemGroupAddons } from '@libs/shared/types';

@Component({
  selector: 'kody-add-on-list',
  templateUrl: './add-on-list.component.html',
  styleUrls: ['./add-on-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddOnListComponent {
  @Input() addons: ItemGroupAddons;
}
