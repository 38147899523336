import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kody-loading-skeleton',
  templateUrl: './loading-skeleton.component.html',
  styleUrls: ['./loading-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSkeletonComponent {
  Arr = Array;
  @Input() cardCount? = 4;
}
