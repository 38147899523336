<div class="overlay" *ngIf="animatedMessageHtml && (activeItem === 'ready' || activeItem === 'collect')"></div>
<div class="timeline" [ngClass]="{ 'animate-circle': activeItem === 'ready' || activeItem === 'collect' }">
  <ul class="timeline__chart">
    <ng-container *ngFor="let item of items">
      <li class="timeline__circle" [attr.data-text]="item" [ngClass]="getClass(items, item)">
        <fa-icon [icon]="faCheck"></fa-icon>
        <div class="animated-container" *ngIf="animatedMessageHtml">
          <div class="animated-container__inner">
            <div class="animated-container__message">
              <p [innerHTML]="animatedMessageHtml"></p>
            </div>
            <a (click)="close()" class="animated-container__close">Click here to close</a>
          </div>
        </div>
      </li>
      <li class="timeline__line" [ngClass]="getClass(items, item)"></li>
    </ng-container>
  </ul>
</div>
