/**
 * Represents a provisioned payment card for Apple Pay payments.
 */
export interface ApplePayPaymentPass {
  /**
   * The unique identifier for the primary account number for the payment card.
   */
  primaryAccountIdentifier: string;

  /**
   * A version of the primary account number suitable for display in your UI.
   */
  primaryAccountNumberSuffix: string;

  /**
   * The unique identifier for the device-specific account number.
   */
  deviceAccountIdentifier?: string;

  /**
   * A version of the device account number suitable for display in your UI.
   */
  deviceAccountNumberSuffix?: string;

  /**
   * The activation state of the pass.
   */
  activationState: string;
}
