import { OrderTypes } from '../../enums/order-types.enum';
import { StoreItem } from '../menu/store-item.interface';
import { PaymentProvider } from '../../types/payment-provider.type';
import { OrderStatus } from '../../types/order-status.type';

export interface RestaurantOrder {
  dateCreated: Date;
  items: StoreItem[];
  itemsCount: number;
  merchantId: string;
  merchantStoreId: string;
  merchantStoreName: string;
  notes: string | null;
  orderNumber: string;
  paymentProvider: PaymentProvider;
  restaurantOrderId: string;
  serviceChargeAmount: number;
  status: OrderStatus;
  tableNumber: string;
  timeSlotDateTime: Date | null;
  timeToComplete: string | null;
  totalAmount: number;
  transactionId: string;
  discountFixedAmount: number | null;
  discountPercentage: number | null;
  // TODO: Remove optional operator once API has been updated (API-418)
  transactionStatus?: 'pending';
  transactionTotalAmount: string;
  userId: string | null;
  userCardId: string | null;
  orderType?: OrderTypes;
  /** Deprecated in favour of orderType */
  paymentType: OrderTypes;
}
