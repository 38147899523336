import { ofType, Actions } from '@ngrx/effects';
import { map, startWith } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StoreFacade } from '@libs/shared/utilities';
import { PlatformService } from '@libs/shared/utilities';
import { RoleObject, User } from '@libs/shared/types';
import { currentSession, getLoggedRoleObj, getUser, isAppInited } from '../../+state/selectors/session.selector';
import { deviceActions, getPingStatus } from '@libs/utility-device';
import { Session } from '../../interfaces/session.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationFacadeService extends StoreFacade {
  constructor(protected store: Store, private platformService: PlatformService, private actions$: Actions) {
    super(store);
  }

  userInitialisationError$ = this.actions$.pipe(
    ofType(deviceActions.pingError),
    map((a) => a.payload),
    startWith(false)
  );

  getLoggedInUserRole(): Observable<RoleObject> {
    return this.store.select(getLoggedRoleObj);
  }

  getUser(): Observable<User> {
    return this.store.select(getUser);
  }

  isWebNoAuth(): Observable<boolean> {
    return this.getLoggedInUserRole().pipe(map((role) => !role && !this.platformService.isNative()));
  }

  userInitialised(): Observable<boolean> {
    return this.store.select(getPingStatus);
  }

  isAppInitialised(): Observable<boolean> {
    return this.store.select(isAppInited);
  }

  getCurrentSession(): Observable<Session> {
    return this.store.select(currentSession);
  }
}
