import { ClickCollectSlot } from '../../../interfaces/menu/click-collect-slot.interface';
import { MerchantStore } from '../../../interfaces/merchant/merchant-store.interface';
import { Cart } from '../../../interfaces/payment/cart.interface';
import { Discount } from '../../../interfaces/payment/discount.interface';
import { AcknowledgedEvent } from '../../../types/acknowledged-event.type';

export interface KodyOrderState {
  currentCart: Cart;
  merchantStore: MerchantStore;
  table?: string;
  clickAndCollectSlot?: ClickCollectSlot;
  discount?: Discount;
  availableClickAndCollectSlots?: ClickCollectSlot[];
  serviceChargeFixed?: number;
  serviceChargePercent?: number;
  acknowledgedEvents?: AcknowledgedEvent[];
}

export interface KodyOrderAppState {
  appState: KodyOrderState;
}
