import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Cart } from '@libs/shared/types';
import { getCurrentCart } from '@libs/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class MerchantSelectionFacadeService {
  constructor(private store: Store) {}

  /** Deprecated in favour of the AppStateFacadeService */
  getCart(): Observable<Cart> {
    return this.store.select(getCurrentCart);
  }
}
