export interface ApplePayMerchantSession {
  displayName: string;
  domainName: string;
  epochTimestamp: number;
  expiresAt: number;
  merchantIdentifier: string;
  merchantSessionIdentifier: string;
  nonce: string;
  operationalAnalyticsIdentifier: string;
  retries: number;
  signature: string;
}
