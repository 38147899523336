import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthenticationFacadeService, sessionActions } from '@libs/utility-authentication';
import { deviceActions } from '@libs/utility-device';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, tap } from 'rxjs';
import { loginApiActions } from '../actions/login-api.actions';
import { CommonHttpErrorResponse } from '@libs/app-kody-order/utility-app-state';
import { ordersRoutes } from '@libs/shared/types';

@Injectable()
export class LoginApiEffects {
  constructor(private actions$: Actions, private authenticationFacadeService: AuthenticationFacadeService, private nav: NavController) {}

  setUserSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginApiActions.loginSuccess),
      map(({ user }) => sessionActions.setSessionUser({ payload: user }))
    )
  );

  navigateToOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginApiActions.loginSuccess),
      switchMap(() =>
        this.authenticationFacadeService.getCurrentSession().pipe(
          filter((session) => !!session),
          filter(({ accessToken }) => !!accessToken),
          map(() => deviceActions.ping()),
          tap(() => this.nav.navigateRoot(ordersRoutes.list, { animated: false }))
        )
      )
    )
  );

  loginFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginApiActions.loginFailure),
      map(({ error }) => {
        if (error.status === 401) {
          return loginApiActions.unauthorised();
        }
        // TODO: Handle errors better (API work required to return specific error codes)
        return CommonHttpErrorResponse({ error });
      })
    )
  );
}
