<ion-grid>
  <ng-container *ngIf="!loading">
    <ng-content *ngIf="itemsCurrentCount"></ng-content>
    <ion-infinite-scroll threshold="100px" (ionInfinite)="onLoadMore()">
      <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data..."> </ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <ng-container *ngIf="!itemsCurrentCount && emptyListConfig">
      <kody-no-data [title]="emptyListConfig.title" [message]="emptyListConfig.message" [image]="emptyListConfig.image"></kody-no-data>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="loading">
    <ion-row *ngFor="let number of rowNumbers">
      <kody-ghost-list-item [height]="itemHeight"></kody-ghost-list-item>
    </ion-row>
  </ng-container>
</ion-grid>
