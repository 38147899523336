export interface StoreSettings {
  autoAcceptOrders: boolean;
  autoAcceptOrdersCC: boolean;
  autoCompleteOrders: boolean;
  autoCompleteOrdersCC: boolean;
  isClickCollect: boolean;
  allowTableOrders: boolean;
  allowCounterOrders: boolean;
  allowClickCollectOrders: boolean;
  waitTimeMinutes?: number;
  merchantId: string;
  merchantStoreId: string;
  location?: string;
  name: string;
  customServiceChargeName?: string;
  dateCreated: string;
  isWaiterAdministeredServiceCharge: boolean;
  unlockServiceChargeCode: string;
}
