export interface CheckoutTokenResponse {
  type: 'applepay' | 'googlepay';
  token: string;
  expires_on: string;
  expiry_month?: number;
  expiry_year?: number;
  scheme?: string;
  last4?: string;
  bin?: string;
  card_type?: string;
  card_category?: string;
  issuer?: string;
  issuer_country?: string;
  product_id?: string;
  product_type?: string;
}
