import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import * as forge from 'node-forge';

import { from, throwError, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, take, tap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { APP_ENVIRONMENT } from '@libs/shared/utilities';
import { authHeaders, Environment, unsupportedVersionHeaders } from '@libs/shared/types';

const key = 'RSAKeyPair';

@Injectable({ providedIn: 'any' })
export class DeviceService {
  private deviceId = '';
  constructor(private http: HttpClient, private platform: Platform, @Inject(APP_ENVIRONMENT) private environment: Environment) {}

  private getKey(): Observable<RSAPair> {
    return from(SecureStoragePlugin.get({ key })).pipe(
      take(1),
      map((value: any) => {
        return JSON.parse(value.value);
      }),
      catchError((err) => {
        return throwError(err);
      })
    ) as Observable<RSAPair>;
  }

  private createKey(): Promise<RSAPair> {
    return new Promise((resolve, reject) => {
      const rsa = forge.pki.rsa;
      rsa.generateKeyPair({ bits: 2048, workers: 2 }, (err, keypair) => {
        if (!err) {
          const pair = {
            publicKeyPem: forge.pki.publicKeyToPem(keypair.publicKey),
            privateKeyPem: forge.pki.privateKeyToPem(keypair.privateKey),
          };
          this.saveKey(key, pair)
            .pipe(take(1))
            .subscribe(() => {
              resolve(pair);
            });
        } else {
          reject(err);
        }
      });
    });
  }

  private saveKey(key, rsaPair: RSAPair): Observable<boolean> {
    const value = JSON.stringify(rsaPair);
    return from(SecureStoragePlugin.set({ key, value })).pipe(
      take(1),
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      })
    ) as Observable<boolean>;
  }

  private getDeviceId(publicKey: string, userAgent: string) {
    let osVendor = 'web';
    if (this.platform.is('ios')) {
      osVendor = 'ios';
    } else if (this.platform.is('android')) {
      osVendor = 'android';
    }
    return this.http.post<DeviceRegistration>(
      `${this.environment.baseApiUrl}/public/device/register`,
      { publicKey, userAgent, osVendor },
      {
        headers: {
          [authHeaders.unsignedHeaderKey]: 'true',
        },
      }
    );
  }

  registerDevice(forceNew?: boolean): Observable<DeviceWithPrivateKey> {
    return this.getKey().pipe(
      map((res) => {
        if (forceNew) {
          throw 'generate new key';
        } else {
          return res;
        }
      }),
      catchError((err, caught) => {
        return from(this.createKey()).pipe(
          tap((pair) => {
            return caught;
          })
        );
      }),
      exhaustMap((res) => {
        return from(Device.getInfo()).pipe(
          exhaustMap((info) => {
            return this.getDeviceId(res.publicKeyPem, info.name || info.model).pipe(
              map((deviceReg) => {
                this.deviceId = deviceReg.deviceId;
                return {
                  deviceId: deviceReg.deviceId,
                  privateKey: res.privateKeyPem,
                };
              })
            );
          })
        );
      })
    );
  }

  signRequest(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    let signString = '';
    let headers: HttpHeaders;
    headers = req.headers.append('Nonce', `${v4()}`);
    if (this.deviceId) {
      headers = headers.append('DeviceId', this.deviceId);
    }
    signString = headers
      .keys()
      .sort()
      .filter((key) => key !== unsupportedVersionHeaders.appVersion)
      .map((key) => key.toLowerCase() + headers.get(key))
      .join('');
    if (req.body) {
      signString += JSON.stringify(req.body);
    }

    return this.getKey().pipe(
      map((key) => {
        const privateKey = forge.pki.privateKeyFromPem(key.privateKeyPem);
        const md = forge.md.sha256.create();
        md.update(signString, 'utf8');
        const signature = forge.util.encode64(privateKey.sign(md));
        const signedHeaders = headers.append('Signature', signature);
        return req.clone({ headers: signedHeaders });
      }),
      catchError((err) => {
        return of(req);
      })
    );
  }
}

export type RSAPair = {
  publicKeyPem: string;
  privateKeyPem: string;
};

export type DeviceRegistration = {
  deviceId: string;
};

export type DeviceWithPrivateKey = {
  deviceId: string;
  privateKey: string;
};
