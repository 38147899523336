import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { StoreClosedPopoverContainerComponent } from './store-closed-popover.component';
import { NoDataComponentModule } from '../../no-data/no-data.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, IonicModule, NoDataComponentModule, FontAwesomeModule],
  declarations: [StoreClosedPopoverContainerComponent],
  exports: [StoreClosedPopoverContainerComponent],
})
export class StoreClosedPopoverComponentModule {}
