export const orderAnalytics = {
  orderNotificationReceived: 'order_notification_received',
  orderNotificationOpened: 'order_notification_opened',
  orderMarkedAsReady: 'order_marked_ready',
  orderStatusUpdated: 'order_status_updated',
  orderCollected: 'order_collected',
  orderReadyAndCollected: 'order_ready_and_collected',
  orderRefunded: 'order_refunded',
} as const;

type Values<T> = T[keyof T];

export type OrderAnalytics = Values<typeof orderAnalytics>;
