import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
@Component({
  selector: 'kodypay-int-tel-input',
  templateUrl: './int-tel-input.component.html',
  styleUrls: ['./int-tel-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntTelInputComponent {
  @Input() phoneNumberControl: FormControl<number>;

  separateDialCode = false;
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  phoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom, CountryISO.China];
}
