import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { User } from '@libs/shared/types';
import { APP_ENVIRONMENT } from '@libs/shared/utilities';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginApiService {
  constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private environment) {}

  login(email: string, password: string): Observable<User> {
    const url = `${this.environment.baseApiUrlMobile}login`;
    return this.http.post(url, { email, password });
  }

  requestResetPasswordLink(email: string): Observable<unknown> {
    const url = `${this.environment.baseApiUrl}/public/password/requestResetLink`;
    return this.http.post(url, { email });
  }
}
