import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ForgotPasswordPageComponent } from './forgot-password-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingModule } from '@libs/shared/ui';
import { PushModule } from '@ngrx/component';
import { ForgotPasswordPageRoutingModule } from './forgot-password-page-routing.module';

@NgModule({
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FontAwesomeModule, LoadingModule, PushModule, ForgotPasswordPageRoutingModule],
  declarations: [ForgotPasswordPageComponent],
  exports: [ForgotPasswordPageComponent],
})
export class ForgotPasswordPageComponentModule {}
