<ion-item button detail="false" class="menu-item">
  <ion-label class="ion-text-wrap">
    <h2 class="menu-item__title" #itemName (resized)="onNameResize($event)">
      {{ item.name }}
      <fa-icon *ngIf="item.description" class="menu-item__info" [icon]="faInfoCircle" (click)="onShowInformation(item, $event)"></fa-icon>
    </h2>
    <p *ngIf="item.description && singleLineTitle" class="menu-item__description">{{ item.description }}</p>
    <kody-store-item-chips [storeItem]="item"></kody-store-item-chips>
    <ng-content select="[merchant-menu-enable-disable]"></ng-content>
    <ion-text class="menu-item__price">
      {{ item.price | currency: '£' }}
    </ion-text>
    <ng-content select="[consumer-menu-quantity]"></ng-content>
  </ion-label>
  <ion-thumbnail class="menu-item__image" *ngIf="item.image">
    <ion-img [src]="baseImageUrl + item.merchantItemId"></ion-img>
  </ion-thumbnail>
</ion-item>
