import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TimeService } from '@libs/utility-date-time';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { scan, startWith, switchMap, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'kody-cooldown-button',
  templateUrl: './cooldown-button.component.html',
  styleUrls: ['./cooldown-button.component.scss'],
})
export class CooldownButtonComponent implements OnChanges {
  @Input() title: string;
  @Input() deferredUntil: Date | null;
  @Output() deferredClick = new EventEmitter<void>();

  private startTimer$ = new BehaviorSubject<number>(0);

  secondsUntil$: Observable<number> = this.startTimer$.pipe(
    switchMap((duration) =>
      timer(0, 1000).pipe(
        startWith(duration),
        scan((s) => s - 1),
        takeWhile((s) => s >= 0)
      )
    )
  );

  constructor(private timeService: TimeService) {}

  ngOnChanges({ deferredUntil }: SimpleChanges): void {
    if (deferredUntil && deferredUntil.currentValue) {
      this.startTimer$.next(this.timeService.getRemainingSeconds(deferredUntil.currentValue) + 1);
    }
  }
}
