import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagsStoreModule } from './store/feature-flags-store.module';

@NgModule({
  imports: [CommonModule, FeatureFlagsStoreModule],
  declarations: [],
  exports: [],
})
export class UtilityFeatureFlagsModule {}
