/**
 * Defines a shipping method for delivering physical goods.
 */
export interface ApplePayShippingMethod {
  /**
   * A short description of the shipping method.
   */
  label: string;

  /**
   * A further description of the shipping method.
   */
  detail?: string;

  /**
   * The amount associated with this shipping method.
   */
  amount: string;

  /**
   * A client-defined identifier.
   */
  identifier?: string;
}
