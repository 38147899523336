import { NgModule } from '@angular/core';
import { GhostListItemComponentModule } from '../ghost-list-item/ghost-list-item.module';
import { NoDataComponentModule } from '../../no-data/no-data.module';
import { ItemsListComponent } from './items-list.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [ItemsListComponent],
  imports: [CommonModule, IonicModule, NoDataComponentModule, GhostListItemComponentModule],
  exports: [ItemsListComponent],
})
export class ItemsListComponentModule {}
