import { GooglePayEnv } from '../../types/google-pay-env.type';

export interface Environment {
  name: string;
  production: boolean;
  baseDomainUrl: string;
  baseApiUrl: string;
  checkoutAPIConf: {
    endpoint: string;
  };
  googlePayEnvironment: GooglePayEnv;
  applePayMerchantId: string;
  launchDarklyClientId: string;
  firebaseConfig?: any;
}
