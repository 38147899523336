import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { map, tap, switchMap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { authenticationGuardActions } from '../actions/authentication/authentication-guard.actions';
import { NEVER, of } from 'rxjs';
import { authenticationInterceptorActions } from '../actions/authentication/authentication-interceptor.actions';
import { AuthenticationFacadeService } from '../../services/authentication-facade/authentication-facade.service';
import { loginActions } from '@libs/feature-login';
@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    private navController: NavController,
    private router: Router,
    private authenticationFacade: AuthenticationFacadeService
  ) {}

  logoutUnauthenticatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationGuardActions.userNotAuthenticated, authenticationInterceptorActions.deviceRegistrationError),
      map(() => loginActions.logout())
    )
  );

  navigateToVerifyEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticationGuardActions.userEmailConfirmationRequired),
        tap(({ email, userId }) => {
          this.navController.navigateRoot(['onboard', 'verify-email'], { queryParams: { email, userId } });
        })
      ),
    { dispatch: false }
  );

  logoutUserIfAuthenticationRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationInterceptorActions.notAuthenticatedError),
      concatLatestFrom(() => [this.authenticationFacade.isAppInitialised(), this.authenticationFacade.isWebNoAuth()]),
      switchMap(([{ error }, isAppInitialised, isWebNoAuth]) => {
        const isLoginEndpoint = error.url?.includes('/login');
        const isPingEndpoint = error.url?.includes('/ping');
        const isOnboardRoute = this.router.url.includes('onboard');

        if (isOnboardRoute || isLoginEndpoint || (isPingEndpoint && (!isAppInitialised || isWebNoAuth))) {
          return NEVER;
        } else {
          return of(loginActions.logout());
        }
      })
    )
  );
}
