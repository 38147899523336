import { Observable } from 'rxjs';

export interface Cardinal {
  /**
   * This optional function allows you to setup high level configuration options for Songbird.
   * Omitting this function from you integration will not cause any issues, it will just result in you receiving default configuration options.
   * This function should be called prior to Songbird.setup.
   */
  configure: (opts: CardinalConfig) => void;
  /**
   * The most common way to start Songbird. The 'init' setup handler is a generic entry point to start Songbird for transaction processing.
   * Every transaction flow will start with a 'init' flow.
   */
  setup: (handler: 'init', jwt: any) => void;
  /**
   * The off function will remove an event listener that was subscribed via the Cardinal.on function.
   * This is a way to clean up any event listeners that may no longer be necessary.
   */
  off: (eventName: string) => void;
  /**
   *  Subscribe to different events and execute a callback function when that event is triggered internally by Songbird
   */
  on: (eventName: string, callback: (data: any, jwt: string) => void) => void;
  /**
   * This function triggers an event within Songbird.
   * This is a way to actively send Songbird data instead of waiting passively for events to occur.
   * */
  trigger: (eventName: string, data: unknown) => Observable<unknown>;
  /**
   * This function allows you to continue a transaction that was started elsewhere.
   * Currently the only payment brand that supports Cardinal.continue() is CCA during a CCA hybrid transaction.
   * When called, this function will trigger and handle all CCA authentication steps when provided with the proper data.
   * The results of the authentication attempt will be returned to the merchant via the payments.validated event
   */
  continue: (paymentBrand: string, continueObj: CardinalContinue, orderDetails: CardinalOrderDetails, jwt?: string) => void;
}

interface CardinalConfig {
  timeout: number;
  extendedtimeout: number;
  maxRequestRetries: number;
  payment: {
    view: 'modal';
  };
  logging: {
    level: 'off' | 'on';
  };
}

interface CardinalContinue {
  AcsUrl: string;
  Payload: unknown;
}

interface CardinalOrderDetails {
  OrderDetails: {
    TransactionId: string;
  };
}
