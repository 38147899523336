import { ItemAddon } from './item-addon.interface';
import { Status } from '../../types/status.type';

export interface ItemAddonGroup {
  addonGroupId: string;
  addonGroupName: string;
  merchantId: string;
  status: Status;
  selectionLimitsEnforced: boolean;
  minSelectionLimit: number;
  maxSelectionLimit: number;
  orderIndex: number;
  addons: ItemAddon[];
}
