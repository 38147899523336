import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperInputComponent } from './stepper-input.component';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StepperUiComponentModule } from '../stepper-ui/stepper-ui.module';

@NgModule({
  imports: [CommonModule, IonicModule, FontAwesomeModule, StepperUiComponentModule],
  declarations: [StepperInputComponent],
  exports: [StepperInputComponent],
})
export class StepperInputComponentModule {}
