import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiPageModalBaseComponent } from './multi-page-modal-base.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [MultiPageModalBaseComponent],
  exports: [MultiPageModalBaseComponent],
})
export class MultiPageModalBaseComponentModule {}
