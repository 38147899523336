import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { CooldownButtonComponent } from './cooldown-button.component';
import { SecondsPipeModule } from '../../pipes/seconds/seconds.pipe.module';

@NgModule({
  declarations: [CooldownButtonComponent],
  exports: [CooldownButtonComponent],
  imports: [CommonModule, FontAwesomeModule, IonicModule, SecondsPipeModule],
})
export class CooldownButtonComponentModule {}
