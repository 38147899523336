import { CollectStatus } from '../../enums/collect-status.enum';
import { StoreItemStatusId } from '../../enums/store-item-status-id.enum';
import { StockItemAddOn } from '../../types/stock-item-add-on.type';
import { UploadFile } from './upload-file.interface';

export interface StoreItem {
  barcode?: string;
  count?: number;
  collectStatus?: CollectStatus;
  description?: string;
  isCustom?: boolean;
  isEatIn?: boolean;
  isGlutenFree?: boolean;
  isOnOffer?: boolean;
  isTakeout?: boolean;
  isVegan?: boolean;
  isVegetarian?: boolean;
  isEighteenPlus?: boolean;
  isClickCollect?: boolean;
  itemId?: string;
  menuCategoryId?: string;
  menuCategoryOrderIndex?: number;
  merchantId?: string;
  merchantItemId?: string;
  merchantStoreIds?: string[];
  name?: string;
  newPrice?: number;
  note?: string;
  offerTypeId?: string;
  price?: string;
  quantity?: number;
  restaurantOrderItemId?: string;
  statusId?: StoreItemStatusId;
  tags: string;
  refundCount?: number;
  image?: UploadFile;
  addonItems: StockItemAddOn[];
  parentItemId?: string;
  parentRestaurantOrderItemId?: string;
  /** Deprecated.  Use tags property instead */
  weight?: string;
}
