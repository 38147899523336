import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreFacade } from '@libs/shared/utilities';
import { notificationsSelectors } from '../selectors/notifications.selectors';

@Injectable({
  providedIn: 'root',
})
export class NotificationsFacadeService extends StoreFacade {
  constructor(protected store: Store) {
    super(store);
  }

  getPushToken(): Observable<string> {
    return this.store.select(notificationsSelectors.selectPushToken);
  }
}
