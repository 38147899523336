<ion-searchbar
  class="kody-search"
  [placeholder]="placeholder"
  [formControl]="searchControl"
  [inputmode]="inputMode"
  [ngClass]="mode"
  [clearIcon]="faClearIcon"
  [searchIcon]="faSearchIcon"
>
  <fa-icon class="kody-search__icon" [icon]="faSearchIcon"></fa-icon>
</ion-searchbar>
