<ion-card class="transaction-row-container dark-text" [style.height]="height - 10 + 'px'">
  <ion-row>
    <ion-col size="7">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="5">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-col>
  </ion-row>
</ion-card>
