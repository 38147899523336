// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'develop',
  production: false,
  baseDomainUrl: 'https://q-dev.kpy.io',
  baseApiUrl: 'https://api-development.kodypay.com',
  checkoutAPIConf: {
    endpoint: 'https://api.sandbox.checkout.com',
  },
  googlePayEnvironment: 'TEST',
  applePayMerchantId: 'merchant.com.kodypay.applepay',
  sentryDsn: 'https://66a0679e05124d9b9ce6480b91a65888@o1085662.ingest.sentry.io/6509091',
  launchDarklyClientId: '62d80d1a2e3d8411b2d225c9',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
