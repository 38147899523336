import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { StoreItem } from '@libs/shared/types';

@Component({
  selector: 'kody-store-item-chips',
  templateUrl: './store-item-chips.component.html',
  styleUrls: ['./store-item-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreItemChipsComponent {
  @Input() storeItem: StoreItem;
}
