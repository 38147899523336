import { OrderTypes } from '../../enums/order-types.enum';
import { PaymentProvider } from '../../types/payment-provider.type';
import { BillingAddress } from './billing-address';
import { CreateRestaurantOrderItemRequest } from './create-restaurant-order-item-request.interface';

export interface CreateRestaurantOrderRequest {
  merchantStoreId: string;
  tableNumber: string;
  items: CreateRestaurantOrderItemRequest[];
  paymentType: OrderTypes;
  totalAmount: string;
  eatIn: boolean;
  paymentProvider: PaymentProvider;
  serviceChargeFixed: number;
  serviceChargePercent: number;
  token: string;
  retryToken: string;
  billingInformation: BillingAddress;
  notes: string;
  timeSlotId?: string;
  discountCode?: string;
  orderId: string;
}
