import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kody-nav-corner-overlay',
  templateUrl: './nav-corner-overlay.component.html',
  styleUrls: ['./nav-corner-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavCornerOverlayComponent {
  @Input() cornerOverlayFill = '#f5f7f9';
}
