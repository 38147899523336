import { Component } from '@angular/core';

@Component({
  selector: 'kody-multi-page-modal-base',
  templateUrl: './multi-page-modal-base.component.html',
})
export class MultiPageModalBaseComponent {
  rootPage: any;
  rootParams: any;
}
