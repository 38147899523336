<ngx-intl-tel-input
  cssClass="int-tel-input"
  [preferredCountries]="preferredCountries"
  [enableAutoCountrySelect]="true"
  [searchCountryFlag]="false"
  [searchCountryField]="[searchCountryField.Iso2, searchCountryField.Name]"
  [selectFirstCountry]="false"
  [selectedCountryISO]="countryISO.UnitedKingdom"
  [maxLength]="15"
  [phoneValidation]="true"
  [separateDialCode]="separateDialCode"
  [numberFormat]="phoneNumberFormat.National"
  name="phoneNumber"
  customPlaceholder="Phone number"
  [formControl]="phoneNumberControl"
></ngx-intl-tel-input>
