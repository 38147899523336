import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'kody-ghost-list-item',
  templateUrl: './ghost-list-item.component.html',
  styleUrls: ['./ghost-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GhostListItemComponent {
  @Input()
  height: number;
}
