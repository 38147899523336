import { NgZone } from '@angular/core';
import { CapacitorService } from '../../../capacitor/services/capacitor.service';

/*
 *  Extend this class to create a native plugin wrapper service
 * */
export abstract class NativeWrapper<T> {
  // Wrapped native plugin, each call is wrapped in an isAvailable check
  plugin: T;

  protected constructor(
    public pluginName: string,
    private capacitorService: CapacitorService,
    private nativePlugin: T,
    private zone?: NgZone
  ) {
    this.plugin = this.createWrappedObject();
  }

  isAvailable(): boolean {
    return this.capacitorService.isPluginAvailable(this.pluginName);
  }

  ifAvailable<T>(then: () => T): T {
    if (this.isAvailable()) {
      return then();
    }
  }

  // Wrap the native plugin calls in isAvailable checks, and run in angular zone if supplied
  private createWrappedObject(): T {
    return new Proxy(this.nativePlugin as any, {
      get: (target: T, p: string) => {
        if (!target[p] || !this.isAvailable()) {
          return () => {};
        }
        const nativeFunction = target[p];
        if (this.zone) {
          return (...args) => this.zone.run(() => nativeFunction(...args));
        }
        return nativeFunction;
      },
    }) as T;
  }
}
