import { Status } from '../../types/status.type';

// TODO: Update to correct response once fixed on the API
export interface UpdateCategoryResponse {
  restaurantMenuCategoryId: string;
  name: string;
  status: Status;
  merchantId: string;
  itemCount?: any;
  order: number;
  description: string;
  addons: any[];
  addonGroups: any[];
  timedAvailability: boolean;
  availabilityTimes: any[];
}
