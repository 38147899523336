import { Inject, Injectable } from '@angular/core';
import { CAPACITOR } from '../tokens/capacitor.token';

@Injectable({
  providedIn: 'root',
})
export class CapacitorService {
  constructor(@Inject(CAPACITOR) private capacitor: { isPluginAvailable: (plugin: string) => boolean }) {}

  isPluginAvailable(plugin: string): boolean {
    return this.capacitor.isPluginAvailable(plugin);
  }
}
