<!-- TODO: Refactor as this is legacy code copied across from the original app -->
<ion-header translucent="true">
  <ion-toolbar>
    <ion-buttons collapse="false">
      <ion-back-button text="" icon="chevron-back-outline" defaultHref="onboard"></ion-back-button>
    </ion-buttons>
    <ion-title>Login</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen="true" #content scrollY="false">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Login</ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="scroll-content-container form-container">
    <form [formGroup]="loginForm" *ngIf="loginForm">
      <div class="form-group">
        <ion-label>Email</ion-label>
        <ion-input
          #emailField
          class="mtop5 mbottom15"
          (change)="loginForm.controls['email'].patchValue($event.target.value)"
          (keyup.enter)="login(loginForm)"
          autocomplete="username"
          type="email"
          formControlName="email"
        ></ion-input>
        <p class="form-group__error" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email">
          Please enter a valid email address
        </p>
      </div>

      <div class="form-group">
        <ion-label>Password</ion-label>
        <div class="mtop5 mbottom15 text-size-14 custom-input-icon p-relative">
          <ion-input
            (change)="loginForm.controls['password'].patchValue($event.target.value)"
            (keyup.enter)="login(loginForm)"
            autocomplete="current-password"
            [type]="passwordVisible ? 'text' : 'password'"
            formControlName="password"
            class="password-input"
          >
          </ion-input>
          <div (click)="passwordVisible = !passwordVisible" class="view-password">
            <fa-icon *ngIf="passwordVisible" class="end-icon-textbox" [icon]="icons.faEye"></fa-icon>
            <fa-icon *ngIf="!passwordVisible" class="end-icon-textbox" [icon]="icons.faEyeSlash"></fa-icon>
          </div>
        </div>
      </div>

      <div class="content-center mbottom20" *ngIf="unauthorised$ | async as unauthorised">
        <ion-text class="red-text">{{ unauthorised }}</ion-text>
      </div>

      <div class="content-center">
        <ion-label><a routerLink="/forgot-password">Forgotten Password?</a></ion-label>
      </div>
    </form>
  </div>
</ion-content>
<ion-footer>
  <ion-button expand="block" (click)="login(loginForm)">
    <div *ngIf="!(loading$ | async); else loading">
      Login
      <fa-icon class="end-icon" [icon]="icons.faChevronRight"></fa-icon>
    </div>
    <ng-template #loading>
      <kody-loading></kody-loading>
    </ng-template>
  </ion-button>
</ion-footer>
