import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { getMerchantItemsByStoreId, GetMerchantItemsByStoreIdQuery } from '../gql/get-merchant-items-by-store-id.qql';
import {
  AllConfigurableMerchantItemsDocument,
  AllConfigurableMerchantItemsQuery,
  GetStoreByIdDocument,
  GetStoreByIdQuery,
  MerchantItemFilter,
  MerchantStore,
  OrderTypes,
  StoreCategory,
  StoreItem,
} from '@libs/shared/types';
import {
  getAllConfigurableMerchantCategories,
  GetAllConfigurableMerchantCategoriesQuery,
} from '../gql/all-configurable-merchant-categories.query';
import { mapGqlResponse } from '../../../functions/gql.functions';

@Injectable({
  providedIn: 'root',
})
// Only used bu
export class MerchantStoreDataService {
  constructor(private apollo: Apollo) {}

  // Keep in shared data-access?
  getMerchantStore(merchantStoreId: string): Observable<MerchantStore> {
    return this.apollo
      .query({
        query: GetStoreByIdDocument,
        variables: { filter: { merchantStoreId: { equalTo: merchantStoreId } } },
      })
      .pipe(map(mapGqlResponse<GetStoreByIdQuery, MerchantStore>((data) => data?.allMerchantStores.nodes[0])));
  }

  // Move to merchant data-access?
  getMerchantStoreMerchantMenuCategories(merchantStoreId: string): Observable<StoreCategory[]> {
    return this.apollo
      .query({
        query: getAllConfigurableMerchantCategories,
        variables: { merchantStoreId: merchantStoreId },
      })
      .pipe(
        map(
          mapGqlResponse<GetAllConfigurableMerchantCategoriesQuery, StoreCategory[]>(
            (data) => data?.configurableMenuCategoriesByMerchantStoreId.nodes
          )
        )
      );
  }

  getStoreItems({
    merchantStoreId,
    orderType,
    eatingIn,
  }: {
    merchantStoreId: string;
    orderType: OrderTypes;
    eatingIn: boolean;
  }): Observable<StoreItem[]> {
    const filter = this.buildStoreItemsFilters({ orderType, eatingIn });

    return this.apollo
      .query({
        query: getMerchantItemsByStoreId,
        variables: { merchantStoreId, filter },
      })
      .pipe(map(mapGqlResponse<GetMerchantItemsByStoreIdQuery, StoreItem[]>((data) => data?.merchantItemsByStoreId.nodes)));
  }

  // Move to merchant data-access?
  getConfigurableMenuItems(): Observable<StoreItem[]> {
    return this.apollo
      .query({ query: AllConfigurableMerchantItemsDocument })
      .pipe(map(mapGqlResponse<AllConfigurableMerchantItemsQuery, StoreItem[]>((data) => data?.allConfigurableMerchantItems.nodes)));
  }

  private buildStoreItemsFilters({ orderType, eatingIn }: { orderType: OrderTypes; eatingIn: boolean }): MerchantItemFilter {
    const filter = {};
    if (orderType == OrderTypes.clickCollect) {
      filter['isClickCollect'] = { equalTo: true };
    } else if (orderType == OrderTypes.counter && eatingIn) {
      filter['isEatIn'] = { equalTo: true };
    } else if (orderType == OrderTypes.counter && !eatingIn) {
      filter['isTakeout'] = { equalTo: true };
    } else if (orderType == OrderTypes.table) {
      filter['isEatIn'] = { equalTo: true };
    }
    return filter;
  }
}
