import { ClickCollectSlot } from '../../../interfaces/menu/click-collect-slot.interface';
import { Cart } from '../../../interfaces/payment/cart.interface';
import { Discount } from '../../../interfaces/payment/discount.interface';
import { AcknowledgedEvent } from '../../../types/acknowledged-event.type';

export interface UserSession {
  entryRoute?: string;
  cart?: Cart;
  table?: string;
  clickCollectSlot?: ClickCollectSlot;
  discount?: Discount;
  smsNotificationNumber?: string;
  acknowledgedEvents?: AcknowledgedEvent[];
}
