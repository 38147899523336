import { Status } from '../../types/status.type';

export interface ItemAddon {
  addonItemId: string;
  merchantItemId: string;
  merchantId: string;
  status: Status;
  name: string;
  description: string;
  tags: string;
  price: string;
  stockCount: number;
  isVegetarian: boolean;
  isVegan: boolean;
  isGlutenFree: boolean;
  isTakeout: boolean;
  isEatIn: boolean;
  isClickCollect: boolean;
  isCustom: boolean;
  hasImage: boolean;
  selectionLimitsEnforced: boolean;
  maxSelectionLimit: number;
  /** Deprecated.  Use tags */
  weight?: string;
}
