<ion-item *ngIf="checkout" (click)="addItem(item, index)" detail="false" button>
  <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
</ion-item>

<ion-item *ngIf="!checkout && isMerchantView">
  <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
</ion-item>

<ion-item *ngIf="!checkout && !isMerchantView" (click)="addItem(item, index)" detail="false" kodyRippleEffect button>
  <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
</ion-item>

<ng-template #menuItemContent>
  <ion-row class="w-100 kp-item" *ngIf="item">
    <ion-col class="padding-0" [size]="mainColumnWidth()">
      <ion-row>
        <ion-col size="12" class="padding-0">
          <ion-text
            #itemName
            class="bold-text black-text text-size-20"
            style="display: block"
            [class.not-available]="!available"
            (resized)="onNameResize($event)"
          >
            {{ item?.name }}
            <fa-icon
              *ngIf="descriptionEnabled && item.description"
              class="info-icon fa-sm kp-item__info-button"
              [class.not-available]="!available"
              [icon]="faInfoCircle"
              (click)="presentModal(item, $event)"
            >
            </fa-icon>
          </ion-text>
        </ion-col>
        <ion-col *ngIf="descriptionEnabled && singleLineTitle" size="12" class="description-padding">
          <ion-text class="medium-text text-size-16 kp-item__description">
            {{ item?.description }}
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="kp-item-sud-desc">
        <ion-col *ngIf="item?.weight" class="padding-0" size="auto">
          <kody-chip color="primary" [unavailable]="!available">
            {{ item?.weight }}
          </kody-chip>
        </ion-col>
        <ion-col class="padding-0" size="auto" *ngIf="item?.isGlutenFree">
          <kody-chip color="success" [unavailable]="!available"> Gf </kody-chip>
        </ion-col>
        <ion-col class="padding-0" size="auto" *ngIf="item?.isVegan || item?.isVegetarian">
          <kody-chip color="success" [unavailable]="!available">
            {{ item.isVegan ? 'Ve' : 'V' }}
          </kody-chip>
        </ion-col>
        <!-- Handle via content projection? -->
        <span *ngIf="isMerchantView">
          <ion-col class="padding-0" size="auto" *ngIf="item.statusId === storeItemStatusId.enabled">
            <kody-chip color="success"> Enabled </kody-chip>
          </ion-col>
          <ion-col class="padding-0" size="auto" *ngIf="item.statusId === storeItemStatusId.disabled">
            <kody-chip color="danger"> Disabled </kody-chip>
          </ion-col>
        </span>
        <ion-col class="padding-0" size="auto">
          <ion-text class="mleft5 bold-text kp-item--price" [class.not-available]="!available">
            {{ price | currency: '£' }}
          </ion-text>
        </ion-col>
        <ng-container *ngIf="!picking && !checkout">
          <ng-container *ngrxLet="cartItemQuantityMap$ as quantityMap">
            <ion-col class="padding-0 mleft10" size="auto" *ngIf="quantity && quantityMap[item.merchantItemId] as quantity">
              <kody-chip color="primary">x{{ quantity || 0 }} </kody-chip>
            </ion-col>
          </ng-container>
        </ng-container>
      </ion-row>
      <ion-row *ngIf="(addons | keyvalue)?.length">
        <ion-col>
          <kody-add-on-list [addons]="addons"></kody-add-on-list>
        </ion-col>
      </ion-row>
    </ion-col>
    <ng-container *ngIf="editable; else number">
      <ng-container *ngIf="refund; else changer">
        <ion-col size="3" *ngIf="item.collectStatus !== collectStatus.noItem">
          <div
            class="refund-checkbox"
            [class.kp-item-full-refund]="refundCount === item.quantity"
            [class.kp-item-partial-refund]="refundCount > 0 && refundCount < item.quantity"
            (click)="checkBoxTap(item, $event)"
          >
            <fa-icon [icon]="faRedoAlt" *ngIf="refundCount === item.quantity; else partialRefund"> </fa-icon>
            <ng-template #partialRefund>
              <ng-container *ngIf="refundCount > 0">
                <span class="partial-kp-item-refund"> {{ refundCount }} of {{ item.quantity }} </span>
              </ng-container>
            </ng-template>
          </div>
        </ion-col>
      </ng-container>
      <ng-template #changer>
        <ng-container *ngIf="picking; else default">
          <ion-col size="3">
            <div
              class="collect-custom-checkbox ion-float-right"
              [class.ccc-noitem]="item.collectStatus === collectStatus.noItem"
              [class.ccc-picked]="item.collectStatus === collectStatus.picked"
              [class.ccc-alternative]="item.collectStatus === collectStatus.alternative"
              (click)="checkBoxTap(item, $event)"
            >
              <fa-icon [icon]="faCheck" *ngIf="item.collectStatus === collectStatus.picked"> </fa-icon>
              <fa-icon [icon]="faExchange" *ngIf="item.collectStatus === collectStatus.alternative"> </fa-icon>
              <fa-icon [icon]="faTimes" *ngIf="item.collectStatus === collectStatus.noItem"> </fa-icon>
            </div>
          </ion-col>
        </ng-container>
        <ng-template #default>
          <ng-container *ngIf="checkout; else count">
            <ion-col size="3" class="kp-item-content-container">
              <ion-input
                [(ngModel)]="quantity"
                class="kp-item__controls-container"
                (ionBlur)="editItemCount(item)"
                type="number"
                inputmode="numeric"
                min="1"
                maxlength="2"
                max="99"
              >
              </ion-input>
            </ion-col>
          </ng-container>
          <ng-template #count>
            <ion-col *ngIf="item.image" size="3" class="padding-0 mbottom8">
              <ion-row>
                <ion-thumbnail class="kp-item-thumbnail" *ngIf="item.image">
                  <img class="kp-item-image" [src]="baseImageUrl + item.merchantItemId" />
                </ion-thumbnail>
              </ion-row>
            </ion-col>
          </ng-template>
        </ng-template>
      </ng-template>
    </ng-container>
    <ng-template #number>
      <ion-col size="3" class="padding-0 kp-item-content-container">
        <ion-text class="black-text quantity text-size-40 bold-text" [class.not-available]="!available">
          {{ quantity || item?.quantity }}
        </ion-text>
      </ion-col>
    </ng-template>
  </ion-row>
</ng-template>
