import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { GhostListItemComponent } from './ghost-list-item.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [GhostListItemComponent],
  exports: [GhostListItemComponent],
})
export class GhostListItemComponentModule {}
