import { PushNotificationSchema } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Inject, Injectable } from '@angular/core';
import { CapacitorService } from '../../../capacitor/services/capacitor.service';
import { LOCAL_NOTIFICATIONS } from '../../tokens/local-notifications.token';
import { NativeWrapper } from '../../../wrappers/services/native-wrapper/native-wrapper';

@Injectable({
  providedIn: 'root',
})
export class LocalNotificationsService extends NativeWrapper<typeof LocalNotifications> {
  constructor(capacitorService: CapacitorService, @Inject(LOCAL_NOTIFICATIONS) private localNotifications: typeof LocalNotifications) {
    super('LocalNotifications', capacitorService, localNotifications);
  }

  async showNotification(notification: PushNotificationSchema): Promise<void> {
    if (notification) {
      const status = await this.localNotifications.checkPermissions();
      if (status.display) {
        await this.localNotifications.schedule({
          notifications: [
            {
              body: notification.body,
              id: Date.now(),
              title: notification.title,
              extra: notification.data,
            },
          ],
        });
      }
    }
  }
}
