export type Ping = new () => PingRequest;
export interface PingRequest {
  ping: (query: PingQuery[], success: (res: PingResponse[]) => void, failure: (err: string) => void) => {};
}

interface PingQuery {
  query: string;
  timeout: number;
  retry: number;
  version: string;
}

export interface PingResponse {
  response: {
    result: PingResponseResult;
    status: string;
  };
  request: {
    query: string[];
    timeout: number;
    retry: number;
    version: 'v4' | 'v6';
  };
}

export interface PingResponseResult {
  target: string;
  avgRtt: number;
  maxRtt: number;
  minRtt: number;
  pctTransmitted: number;
  pctReceived: number;
  pctLoss: string;
}
