<ng-container *ngIf="!(loading$ | ngrxPush); else loading">
  <ion-header translucent="true">
    <ion-toolbar>
      <ion-buttons collapse="false">
        <ion-back-button text="" icon="chevron-back-outline" defaultHref="onboard/login"></ion-back-button>
      </ion-buttons>
      <ion-title>Forgot Password</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content fullscreen="true" #content>
    <ion-header collapse="condense">
      <ion-toolbar>
        <ion-title size="large">Forgot Password</ion-title>
      </ion-toolbar>
    </ion-header>

    <form [formGroup]="form">
      <div class="form-group">
        <ion-label>Email</ion-label>
        <ion-input
          class="mtop5 mbottom15"
          type="email"
          formControlName="email"
          (keyup.enter)="resetPass(form.get('email').value)"
        ></ion-input>
        <p class="form-group__error" *ngIf="form.controls.email.touched && form.controls.email.errors?.email">
          Please enter a valid email address
        </p>
      </div>
    </form>
  </ion-content>

  <ion-footer>
    <ion-toolbar>
      <ion-button expand="block" class="mbottom20" (click)="resetPass(form.get('email').value)"> Reset Password </ion-button>
    </ion-toolbar>
  </ion-footer>
</ng-container>
<ng-template #loading>
  <kody-loading [page]="true"></kody-loading>
</ng-template>
