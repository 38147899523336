<ion-button
  *ngIf="{ val: secondsUntil$ | async } as secondsUntilObj"
  fill="outline"
  expand="block"
  [disabled]="secondsUntilObj.val > 0"
  (click)="deferredClick.emit()"
>
  {{ title }}{{ secondsUntilObj.val > 0 ? ' in ' + (secondsUntilObj.val | seconds) : '' }}
  <ng-content select="[icon]"></ng-content>
</ion-button>
