import { Inject, Injectable } from '@angular/core';
import { Intercom } from '@capacitor-community/intercom';
import { INTERCOM } from '../tokens/intercom.token';
import { CapacitorService } from '../capacitor/services/capacitor.service';
import { NativeWrapper } from './services/native-wrapper/native-wrapper';

@Injectable({ providedIn: 'root' })
export class IntercomService extends NativeWrapper<typeof Intercom> {
  constructor(capacitorService: CapacitorService, @Inject(INTERCOM) intercom: typeof Intercom) {
    super('Intercom', capacitorService, intercom);
  }

  open(): void {
    this.plugin.displayMessageComposer({ message: 'Hello there!' });
  }
}
