import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ItemInformationModalComponent } from './item-information-modal.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [ItemInformationModalComponent],
  exports: [ItemInformationModalComponent],
})
export class ItemInformationModalComponentModule {}
