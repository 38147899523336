import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  transform(value: string): string {
    const firstLetter = value.substr(0, 1).toUpperCase();
    return `${firstLetter}${value.toLowerCase().substr(1)}`;
  }
}
