import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef, ViewChild, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { StoreItem } from '@libs/shared/types';
import { ResizedEvent } from 'angular-resize-event';

@Component({
  selector: 'kody-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent implements OnInit {
  @Input() item: StoreItem;
  @Input() baseApiUrlCms: string;

  @Output() showInformation = new EventEmitter<StoreItem>();

  @ViewChild('itemName', { read: ElementRef, static: false }) itemName: ElementRef<HTMLElement>;
  readonly faInfoCircle = faInfoCircle;
  singleLineTitle = false;
  baseImageUrl: string;

  ngOnInit(): void {
    this.baseImageUrl = `${this.baseApiUrlCms}/merchantItem/image/`;
  }

  onNameResize({ newRect }: ResizedEvent): void {
    const lineHeight = parseInt(getComputedStyle(this.itemName.nativeElement).lineHeight.slice(0, -2));
    // Disable description if name is over 1 line
    this.singleLineTitle = newRect.height / lineHeight < 2;
  }

  onShowInformation(item: StoreItem, event: Event): void {
    event.stopPropagation();
    this.showInformation.emit(item);
  }
}
