import { PingResponseResult } from './ping.interface';

export interface NetworkMonitoringCacheItem {
  type: 'networking';
  dateTime: string;
  data: NetworkMonitoringData;
}

export interface NetworkMonitoringData extends PingResponseResult {
  status: string;
  networkType: string;
}

export interface NetworkMonitoringPayload {
  merchantStoreId: string;
  deviceId: string;
  measurements: NetworkMonitoringCacheItem[];
}
