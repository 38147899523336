import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemComponent } from './item.component';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { ChipComponentModule } from '../../chip/chip.module';
import { AddOnListComponentModule } from '../../add-on-list/add-on-list.module';
import { LetModule } from '@ngrx/component';
import { LongPressModule } from '../../long-press/long-press.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FontAwesomeModule,
    ChipComponentModule,
    FormsModule,
    AddOnListComponentModule,
    LetModule,
    LongPressModule,
  ],
  declarations: [ItemComponent],
  exports: [ItemComponent],
})
export class ItemComponentModule {}
