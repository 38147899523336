import { BillingAddress } from './billing-address';

export interface FramesTokenizationSuccess {
  type: string;
  token: string;
  expires_on: Date;
  billing_address: Partial<BillingAddress>;
  expiry_month: number;
  expiry_year: number;
  name: string;
  scheme: string;
  last4: string;
  bin: string;
  card_type: string;
  card_category: string;
  issuer: string;
  issuer_country: string;
  product_id: string;
  product_type: string;
}
