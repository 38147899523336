import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'kody-stats-card',
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsCardComponent {
  @Input()
  set value(value: number) {
    this.integer = formatNumber(Math.floor(value), 'en-GB');
    // remove integer part, including comma separators, and ensure 2 digits, eg 15,000.3 -> .30
    this.decimal = formatNumber(value, 'en-GB', '0.2-2').replace(/[0-9,]+(\.)/, '$1');
  }
  @Input() isCurrency = false;
  @Input() title: string;
  integer: string;
  decimal: string;
}
