import { StoreSettings } from './store-settings.interface';

export type StoreSettingsForm = Pick<
  StoreSettings,
  | 'autoAcceptOrders'
  | 'autoAcceptOrdersCC'
  | 'autoCompleteOrders'
  | 'autoCompleteOrdersCC'
  | 'waitTimeMinutes'
  | 'isWaiterAdministeredServiceCharge'
  | 'unlockServiceChargeCode'
> & {
  closedForNow: boolean;
  closedForNowCC: boolean;
};
