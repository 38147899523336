import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularStorageService } from './storage/angular-storage/angular-storage.service';
import { IonicStorageModule } from '@ionic/storage-angular';

@NgModule({
  imports: [CommonModule, IonicStorageModule.forRoot()],
  providers: [Storage, AngularStorageService],
})
export class SharedUtilitiesModule {}
