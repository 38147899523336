/**
 * Encapsulates contact information needed for billing and shipping.
 */
export interface ApplePayPaymentContact {
  /**
   * An email address for the contact.
   */
  emailAddress: string;

  /**
   * The contact's family name.
   */
  familyName: string;

  /**
   * The contact's given name.
   */
  givenName: string;

  /**
   * A phone number for the contact.
   */
  phoneNumber: string;

  /**
   * The address for the contact.
   */
  addressLines: string[];

  /**
   * The city for the contact.
   */
  locality: string;

  /**
   * The state for the contact.
   */
  administrativeArea: string;

  /**
   * The post code, where applicable, for the contact.
   */
  postalCode: string;

  /**
   * The colloquial country name for the contact.
   */
  country: string;

  /**
   * The contact's ISO country code.
   */
  countryCode: string;
}
