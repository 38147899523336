import { Role } from '../../enums/role.enum';

export interface RoleObject {
  isClickCollect?: boolean;
  isHotFrozen?: boolean;
  isRestaurant?: boolean;
  isRetail?: boolean;
  merchantId?: string;
  merchantName?: string;
  name?: Role;
  storeId?: string;
  storeName?: string;
  enableVatExemption?: boolean;
}
