import { PaymentMethods } from '../../enums/payment/payment-methods.enum';

export interface Card {
  type?: string;
  expirationMonth?: string;
  expirationYear?: string;
  userCardId?: string;
  userId?: string;
  number?: string;
  shortNumber?: string;
  firstName?: string;
  lastName?: string;
  status?: string;
  currentIndex?: number;
  paymentProvider?: PaymentMethods;
  label?: string;
}
