import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import { forgotPasswordPageActions } from '../../+state/actions/forgot-password.actions';
import { loginActions } from '../../+state/actions/login.actions';
import { LoginFacadeService } from '../../+state/services/login-facade/login-facade.service';

@Component({
  selector: 'kodypay-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss'],
})
export class ForgotPasswordPageComponent implements ViewDidEnter, ViewDidLeave {
  readonly faChevronRight = faChevronRight;
  loading$ = this.loginFacadeService.getForgotPasswordPageLoading();
  form = new FormGroup({
    email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
  });

  constructor(private loginFacadeService: LoginFacadeService) {}

  ionViewDidEnter(): void {
    this.loginFacadeService.dispatch(forgotPasswordPageActions.enterPage());
  }

  ionViewDidLeave(): void {
    this.loginFacadeService.dispatch(forgotPasswordPageActions.leavePage());
  }

  resetPass(email: string): void {
    if (this.form.valid) {
      this.loginFacadeService.dispatch(forgotPasswordPageActions.resetPassword({ email }));
    } else {
      this.form.markAllAsTouched();
    }
  }
}
