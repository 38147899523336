import { faChevronRight, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewChild } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { IonInput, ViewDidEnter, ViewWillEnter } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { loginActions, LoginFacadeService } from '@libs/feature-login';
import { LoginForm } from '@libs/shared/types';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'kodypay-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements ViewWillEnter, ViewDidEnter {
  @ViewChild('emailField') emailField: IonInput;
  readonly icons = {
    faChevronRight: faChevronRight,
    faEye: faEye,
    faEyeSlash: faEyeSlash,
  };
  loading$: Observable<boolean>;
  unauthorised$: Observable<string | HttpErrorResponse>;
  loginForm: FormGroup<LoginForm>;
  passwordVisible = false;

  constructor(private route: ActivatedRoute, private loginFacadeService: LoginFacadeService) {}

  ionViewWillEnter(): void {
    this.loginForm = new FormGroup({
      email: new FormControl<string>('', [Validators.required, Validators.email]),
      password: new FormControl<string>('', Validators.required),
    });
    this.loading$ = this.loginFacadeService.getLoginPageLoadingState();
    this.unauthorised$ = this.loginFacadeService.getUnauthorised();
    this.route.queryParams.subscribe((params) => {
      const email = params['email'];
      if (email) {
        this.loginForm.patchValue({ email });
      }
    });
  }

  ionViewDidEnter(): void {
    this.loginFacadeService.dispatch(loginActions.enterPage());
    if (this.emailField) {
      this.emailField.setFocus();
    }
  }

  login(loginForm: FormGroup<LoginForm>): void {
    if (loginForm.valid) {
      this.loginFacadeService.dispatch(
        loginActions.login({ email: loginForm.value.email.toLowerCase(), password: loginForm.value.password })
      );
    } else {
      loginForm.markAsTouched();
    }
  }

  ionViewDidLeave(): void {
    this.loginFacadeService.dispatch(loginActions.leavePage());
  }
}
